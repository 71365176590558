import { useState, useRef } from "react";

const useFetchProductionData = ({
  getProductionDataFunction,
  sortByTotalSum,
  toggleCommercialOfferVisibility,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [productionData, setProductionData] = useState([]);
  const [sortedData, setSortedData] = useState([]);
  const [kadDistance, setKadDistance] = useState(null);
  const [productionAddresses, setProductionAddresses] = useState([]);
  const [optimalData, setOptimalData] = useState(null);
  const [optimalDeliveryMethod, setOptimalDeliveryMethod] = useState("");
  const cache = useRef({});

  const fetchProductionData = async ({
    isCostComparisonVisible,
    isSelfPickup,
    selectedDeliveryOption,
    paymentMethod,
    clientCoordinates,
    totalWeight,
    totalVolume,
    namesAndQuantities,
    sortDirection,
  }) => {
    if (!isCostComparisonVisible) return;

    setIsLoading(true);

    const key = JSON.stringify({
      selectedDeliveryOption,
      paymentMethod,
      clientCoordinates,
      totalWeight,
      namesAndQuantities,
    });

    if (cache.current[key]) {
      const cached = cache.current[key];
      setProductionData(cached.data);
      setSortedData(sortByTotalSum(cached.data, sortDirection));
      setKadDistance(cached.kadDistance);
      setProductionAddresses(cached.addressesProductions);
      setOptimalData(cached.optimalData);
      setOptimalDeliveryMethod(cached.optimalDeliveryMethod);
      setIsLoading(false);
      return;
    }

    try {
      const sposob_dostavki = isSelfPickup ? "" : selectedDeliveryOption;
      const forma_oplati = paymentMethod;
      const kuda_edem = isSelfPickup ? "" : clientCoordinates;
      const obshaya_massa = parseFloat(totalWeight).toFixed(2);
      const obshiy_volume = parseFloat(totalVolume).toFixed(2);
      const spisok = namesAndQuantities;

      const {
        data: res,
        kadDistance: kadDistanceResult,
        addressesProductions,
        optimalData: optimalRoute,
        optimalDeliveryMethod: deliveryMethod,
      } = await getProductionDataFunction(
        spisok,
        kuda_edem,
        forma_oplati,
        sposob_dostavki,
        obshaya_massa,
        obshiy_volume
      );

      if (!isSelfPickup && !optimalRoute) {
        throw new Error("Optimal route not found");
      }

      setOptimalData(isSelfPickup ? null : optimalRoute);
      setOptimalDeliveryMethod(isSelfPickup ? "" : deliveryMethod);

      const replaceNaN = (data) =>
        data.map((row) =>
          row.map((value) => (value === "NaN" ? "по запросу" : value))
        );

      const normalizedData = replaceNaN(res);

      setKadDistance(kadDistanceResult);
      setProductionAddresses(addressesProductions);
      setProductionData(normalizedData);
      setSortedData(sortByTotalSum(normalizedData, sortDirection));

      cache.current[key] = {
        data: normalizedData,
        kadDistance: kadDistanceResult,
        addressesProductions,
        optimalData: isSelfPickup ? null : optimalRoute,
        optimalDeliveryMethod: deliveryMethod,
      };

      toggleCommercialOfferVisibility(!isSelfPickup);
    } catch (error) {
      console.error("Не удалось загрузить данные:", error);
      toggleCommercialOfferVisibility(false);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    isLoading,
    productionData,
    sortedData,
    setSortedData,
    kadDistance,
    productionAddresses,
    optimalData,
    optimalDeliveryMethod,
    fetchProductionData,
  };
};

export default useFetchProductionData;
