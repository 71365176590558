let cachedData = null; // Кэшированные данные, чтобы не запрашивать их повторно
let cachedDestination = null; // Кэшированное место назначения
let cachedDistances = {}; // Кэшированные расстояния для каждого производства
let cachedKadDistance = null; // Кэшированное расстояние до КАД
let cachedCoords = null;
let globalCheapestMethod = null; // Глобальная переменная для хранения результата calculateCheapestDeliveryMethod


const { fetchDataOS, fetchDistance } = require("./fetches.js"); // Импорт функций для получения данных и расчета расстояний
const defaultCoord = "10.000000, 10.000000"; // Координаты по умолчанию

let cachedCombinations = null; // Кэшированные комбинации

// Фильтруем нежелательные элементы
function filterExcludedProductions(productions) {
  const excludedProductions = new Set([
    "СРЕДНИЕ ЦЕНЫ ОТ ЛОГИСТОВ",
    "Производства",
  ]);
  return productions.filter(
    (production) => !excludedProductions.has(production)
  );
}

// Генерация всех возможных комбинаций с фильтрацией нежелательных элементов
function generateAllCombinations(productions) {
  const result = new Set();
  const maxAdditionalProductions = 3; // для комбинаций от 1 до 4 производств

  function combine(acc, rest, startIndex) {
    const sortedAcc = [...acc].sort();
    result.add(JSON.stringify(sortedAcc));

    if (acc.length === maxAdditionalProductions + 1) return;

    for (let i = startIndex; i < rest.length; i++) {
      acc.push(rest[i]);
      combine(acc, rest, i + 1);
      acc.pop();
    }
  }

  combine([], productions, 0);
  return Array.from(result).map((item) => JSON.parse(item));
}

// Инициализация кэша при запуске
function initializeCombinationCache(productions) {
  if (!cachedCombinations) {
    const filteredProductions = filterExcludedProductions(productions);
    cachedCombinations = generateAllCombinations(filteredProductions);
    //console.log(`Сгенерировано ${cachedCombinations.length} комбинаций`);
  }
}

async function isPointInsideKAD(pointToCheck, kadExit, nearestDistance) {
  const knownInsidePoint = "59.954010, 30.355396";

  // Используем getCachedDistance вместо fetchDistance
  const distanceFromInsidePointToExit = await getCachedDistance(
    knownInsidePoint,
    kadExit
  );
  const distanceFromCheckPointToInsidePoint = await getCachedDistance(
    pointToCheck,
    knownInsidePoint
  );

  // Сравнение расстояний для определения местоположения точки
  if (distanceFromInsidePointToExit > distanceFromCheckPointToInsidePoint) {
    return 0; // Точка внутри КАД
  } else {
    return nearestDistance; // Точка за пределами КАД
  }
}



async function calculateCheapestDeliveryMethod(
  productList,
  destination,
  totalWeight,
  totalVolume
) {
  try {
    if (cachedData === null) {
      cachedData = await fetchDataOS();
    }

    if (!cachedData || !Array.isArray(cachedData.deliverySheet)) {
      console.error("Ошибка: deliverySheet не загружен или не является массивом.");
      return { cheapestMethod: null, lowestCost: Number.MAX_VALUE };
    }

    const deliverySheet = cachedData.deliverySheet;
    let cheapestMethod = null;
    let lowestCost = Number.MAX_VALUE;

    for (const deliveryMethod of deliverySheet) {
      const method = deliveryMethod[0];
      if (!Array.isArray(deliveryMethod) || deliveryMethod.length < 4) {
        console.error("Ошибка: deliveryMethod не является массивом или имеет недостаточную длину.", deliveryMethod);
        continue;
      }
      const [maxCapacity, maxVolume, minCharge, costPerKm] = deliveryMethod
        .slice(1)
        .map((value) => parseFloat(value.toString().replace(",", ".")));
      const nearestExit = await nearestExitKad(
        cachedData.pointsKadSheet,
        destination
      );
      const nearestDistanceOutsideKAD = await isPointInsideKAD(
        destination,
        nearestExit.coordinates,
        nearestExit.distance
      );
      const deliveryCostPerTrip =
        parseInt(minCharge) + costPerKm * nearestDistanceOutsideKAD;

      const numOfTripsMass = Math.ceil(totalWeight / maxCapacity);
      const numOfTripsVolume = Math.ceil(totalVolume / maxVolume);
      const numOfTrips = Math.max(numOfTripsMass, numOfTripsVolume);
      const totalCost = deliveryCostPerTrip * numOfTrips;

      if (totalCost < lowestCost) {
        lowestCost = totalCost;
        cheapestMethod = method;
      }
    }
    globalCheapestMethod = cheapestMethod;

    /*console.log(
      `Самый дешевый метод доставки: ${cheapestMethod}, Стоимость: ${lowestCost} руб.`
    );*/
    return { cheapestMethod, lowestCost };
  } catch (error) {
    console.error("Ошибка при расчете самого дешевого метода доставки:", error);
    throw error;
  }
}

async function calculateTotalCostsWithStops(
  mainProduction,
  additionalProductions,
  productList,
  materialCosts,
  numOfTrips,
  deliveryCostPerTrip,
  updateDates, // Передаем даты обновления
  kadDistance // Добавляем параметр kadDistance
) {
  let totalMaterialCost = 0;
  const productSources = {};

  // Основное производство
  if (materialCosts[mainProduction]) {
    totalMaterialCost += materialCosts[mainProduction].reduce((sum, item) => {
      const product = productList.find(([name]) => name === item.productName);
      if (product) {
        productSources[item.productName] = {
          production: mainProduction,
          updateDate: formatDate(updateDates[mainProduction]),
        };
      }
      return sum + item.price * (product ? product[1] : 0);
    }, 0);
  }

  // Дополнительные производства
  additionalProductions.forEach((production) => {
    if (materialCosts[production]) {
      totalMaterialCost += materialCosts[production].reduce((sum, item) => {
        const product = productList.find(([name]) => name === item.productName);
        if (product && !productSources[item.productName]) {
          productSources[item.productName] = {
            production: production,
            updateDate: formatDate(updateDates[production]),
          };
        }
        return sum + item.price * (product ? product[1] : 0);
      }, 0);
    }
  });

  // Проверяем расстояние до КАД для определения дополнительной стоимости
  const additionalCost =
    (kadDistance === 0 ? 500 : 1000) *
    numOfTrips *
    additionalProductions.length;
  //console.log("additionalCost ", additionalCost);
  //console.log("totalMaterialCost ", totalMaterialCost);
  //console.log("numOfTrips ", numOfTrips);
  //console.log("deliveryCostPerTrip ", deliveryCostPerTrip);
  const totalCost =
    totalMaterialCost + numOfTrips * deliveryCostPerTrip + additionalCost;

  return {
    mainProduction,
    additionalProductions,
    totalMaterialCost,
    additionalCost,
    totalCost,
    deliveryCostPerTrip,
    numOfTrips,
    productSources,
  };
}


async function findOptimalProductionRoute(
  allProductions,
  productList,
  materialCosts,
  numOfTrips,
  updateDates,
  deliveryCostPerTrip,
  kadDistance
) {
  const allRoutes = [];

  // Проверяем, если все товары можно получить с одного производства
  const singleProductionOptions = allProductions.filter(production => 
    productList.every(([productName]) => 
      materialCosts[production] && 
      materialCosts[production].some(item => item.productName === productName)
    )
  );

  // Проверяем стоимость на каждом производстве, если только одно используется
  if (singleProductionOptions.length > 0) {
    const singleProductionCosts = await Promise.all(singleProductionOptions.map(async production => {
      return await calculateTotalCostsWithStops(
        production,
        [],
        productList,
        materialCosts,
        numOfTrips,
        deliveryCostPerTrip,
        updateDates,
        kadDistance
      );
    }));

    // Найти производство с минимальной суммой материалов
    const cheapestSingleProductionRoute = singleProductionCosts.reduce((cheapest, current) => 
      current.totalMaterialCost < cheapest.totalMaterialCost ? current : cheapest
    );

    allRoutes.push(cheapestSingleProductionRoute);
  }

  // Проверяем комбинации производств, если нет подходящих одиночных производств
  if (productList.length > 1 && cachedCombinations) {
    const routePromises = cachedCombinations.map(async (combination) => {
      const mainProduction = combination[0];
      const additionalProductions = combination.slice(1);

      // Проверяем, закрываются ли все товары комбинацией производств
      const productsCovered = productList.every(([productName]) => 
        combination.some(production => 
          materialCosts[production] &&
          materialCosts[production].some(item => item.productName === productName)
        )
      );

      if (!productsCovered) return null;

      try {
        const routeCost = await calculateTotalCostsWithStops(
          mainProduction,
          additionalProductions,
          productList,
          materialCosts,
          numOfTrips,
          deliveryCostPerTrip,
          updateDates,
          kadDistance
        );

        // Фильтрация маршрутов с некорректными данными
        if (routeCost && typeof routeCost === 'object' && routeCost.totalCost !== undefined) {
          return routeCost;
        } else {
          console.warn("Некорректные данные в routeCost:", routeCost);
          return null;
        }
      } catch (error) {
        console.error("Ошибка при расчете маршрута:", error);
        return null;
      }
    });

    const allRoutesResolved = await Promise.all(routePromises);
    allRoutes.push(...allRoutesResolved.filter(route => route !== null));
  }

  // Фильтруем маршруты с допустимыми значениями стоимости
  let validRoutes = allRoutes.filter(route => route.totalCost < Number.MAX_VALUE);

  // Если не найдено ни одного валидного маршрута, попробуем использовать "СРЕДНИЕ ЦЕНЫ ОТ ЛОГИСТОВ"
  if (validRoutes.length === 0) {
    const logisticProduction = "СРЕДНИЕ ЦЕНЫ ОТ ЛОГИСТОВ";
    const productsFromLogistics = productList.filter(([productName]) =>
      materialCosts[logisticProduction]?.some(item => item.productName === productName)
    );

    const missingProducts = productList.filter(([productName]) =>
      !productsFromLogistics.some(([name]) => name === productName)
    );

    // Если "СРЕДНИЕ ЦЕНЫ ОТ ЛОГИСТОВ" покрывает часть товаров, ищем недостающие
    if (productsFromLogistics.length > 0) {

      // Находим комбинации других производств для недостающих товаров
      const additionalRoutes = await Promise.all(cachedCombinations.map(async (combination) => {
        const productsCovered = missingProducts.every(([productName]) =>
          combination.some(production =>
            materialCosts[production] &&
            materialCosts[production].some(item => item.productName === productName)
          )
        );

        if (!productsCovered) return null;

        try {
          const routeCost = await calculateTotalCostsWithStops(
            logisticProduction,
            combination,
            productList,
            materialCosts,
            numOfTrips,
            deliveryCostPerTrip,
            updateDates,
            kadDistance
          );

          if (routeCost && typeof routeCost === 'object' && routeCost.totalCost !== undefined) {
            return routeCost;
          } else {
            console.warn("Некорректные данные в routeCost:", routeCost);
            return null;
          }
        } catch (error) {
          console.error("Ошибка при расчете маршрута:", error);
          return null;
        }
      }));

      validRoutes = additionalRoutes.filter(route => route !== null && route.totalCost < Number.MAX_VALUE);
    }
  }

  // Сортируем маршруты по общей стоимости
  validRoutes.sort((a, b) => a.totalCost - b.totalCost);

  // Если есть валидные маршруты, выбираем самый дешевый
  let optimalRoute = validRoutes[0];

  // Обновляем даты обновления цен для оптимального маршрута
  const relevantProductions = optimalRoute?.productSources 
    ? Object.values(optimalRoute.productSources).map(source => source.production)
    : [];

  const relevantUpdateDates = relevantProductions
    .map(production => updateDates[production])
    .filter(date => date && !isNaN(date.getTime()));

  const minUpdateDate = relevantUpdateDates.length > 0 
    ? new Date(Math.min(...relevantUpdateDates))
    : null;

  if (optimalRoute) {
    optimalRoute.updateDates = minUpdateDate 
      ? formatDate(minUpdateDate)
      : formatDate(new Date(1999, 0, 1));
  } else {
    optimalRoute = {
      updateDates: formatDate(new Date(1999, 0, 1))
    };
  }

 // console.log("Оптимальный маршрут найден:", optimalRoute);

  return optimalRoute;
}


async function getProductionData(
  productList,
  destination,
  paymentMethod,
  deliveryMethod,
  totalWeight,
  totalVolume
) {



  try {
   /* console.log("Product List:", productList);
    console.log("Destination:", destination);
    console.log("Payment Method:", paymentMethod);
    console.log("Delivery Method:", deliveryMethod);
    console.log("Total Weight:", totalWeight);
    console.log("Total Volume:", totalVolume);*/

    if (cachedData === null) {
      cachedData = await fetchDataOS();
    }

     // Если выбран "Оптимальный способ", вычисляем самый дешевый метод доставки
     if (deliveryMethod === "Оптимальный способ") {
      const result = await calculateCheapestDeliveryMethod(
        productList,
        destination,
        totalWeight,
        totalVolume
      );
      deliveryMethod = result.cheapestMethod; // Присваиваем найденный метод доставки
      //console.log(`Используем самый дешевый метод доставки: ${deliveryMethod}`);
    }

    //calculateCheapestDeliveryMethod(productList, destination, totalWeight, totalVolume);

    const deliverySheet = cachedData.deliverySheet;
    const pointsKadSheet = cachedData.pointsKadSheet;
    const stockSheet = cachedData.stockSheet;
    const productionSheet = cachedData.productionSheet;
    const productNames = productList.map((product) => product[0]);

    const allProductions = productionSheet.map((production) => production[0]);
    initializeCombinationCache(allProductions);

    const suitableProductions = filterProductionsByAllProducts(
      stockSheet,
      productNames
    );
    const productionNames = productionSheet.map((production) => production[0]);

    const addressesProductions = suitableProductions.reduce(
      (acc, production) => {
        const productionIndex = productionNames.indexOf(production);
        // Проверка существования индекса и данных
        if (
          productionIndex !== -1 &&
          productionSheet[productionIndex] &&
          productionSheet[productionIndex].length > 2
        ) {
          const address = productionSheet[productionIndex][2];
          acc[production] = address;
        } else {
          console.warn(
            `Не удалось найти адрес для производства: ${production}`
          );
          acc[production] = "Адрес не найден"; // Или другой подходящий дефолтный адрес
        }

        return acc;
      },
      {}
    );

    let materialCosts = suitableProductions.reduce((acc, production, index) => {
      acc[production] = productList.reduce((sum, product) => {
        const [productName, productQuantity] = product;
        const priceColumn = paymentMethod === "cash" ? 4 : 5;
        const price = stockSheet
          .find((row) => row[1] === production && row[2] === productName)
          [priceColumn].replace(/\s/g, "");
        return sum + parseInt(price) * parseInt(productQuantity);
      }, 0);
      return acc;
    }, {});

    const updateDates = allProductions.reduce((acc, production) => {
      const dates = productList
        .map((product) => {
          const productName = product[0];
          const productRow = stockSheet.find(
            (row) => row[1] === production && row[2] === productName
          );
          if (productRow) {
            return new Date(productRow[6].split(".").reverse().join("-"));
          }
          return null; // Возвращаем null, если строки продукта нет
        })
        .filter((date) => date !== null); // Фильтруем null значения

      if (dates.length > 0) {
        acc[production] = new Date(
          Math.min(...dates.map((date) => date.getTime()))
        );
      } else {
        acc[production] = null; // Устанавливаем null, если нет дат
      }
      return acc;
    }, {});

    if (destination === "" && deliveryMethod === "") {
      const result = {
        data: suitableProductions.map((production, index) => {
          return [
            production,
            "0",
            formatNumberWithSpaces(materialCosts[production]), // Исправлено для корректного отображения
            "0",
            "0",
            formatNumberWithSpaces(materialCosts[production]), // Исправлено для корректного отображения
            formatDate(updateDates[production]),
          ];
        }),
        kadDistance: 0,
        addressesProductions,
      };

    //  console.log("Результат при пустом destination и deliveryMethod:", result);

      return result;
    }
    //calculateCheapestDeliveryMethod(productList, destination, totalWeight, totalVolume);

    let nearestDistance, nearestCoords;

     // Проверка изменения destination
     if (destination !== cachedDestination) {
      //console.log("Обновление ближайшего выхода на КАД, так как destination изменился...");
      ({ distance: nearestDistance, coordinates: nearestCoords } =
        await nearestExitKad(pointsKadSheet, destination));
      cachedKadDistance = nearestDistance;
      cachedCoords = nearestCoords;
      cachedDestination = destination;
    } else {
      //console.log("Использование кэшированного значения ближайшего выхода на КАД.");
      nearestDistance = cachedKadDistance;
      nearestCoords = cachedCoords;
    }




    const distances = {};
    await Promise.all(
      suitableProductions.map(async (production) => {
        const productionIndex = productionNames.indexOf(production);
        if (
          productionIndex !== -1 &&
          productionSheet[productionIndex] &&
          productionSheet[productionIndex].length > 1
        ) {
          const coord = productionSheet[productionIndex][1] || defaultCoord;

          if (cachedDistances[coord + "-" + destination]) {
            distances[production] = cachedDistances[coord + "-" + destination];
          } else {
            const distance = await getCachedDistance(coord, destination); // Используем кэш здесь
            distances[production] = distance;
          }
        } else {
          console.warn(`Не удалось найти координаты для производства: ${production}`);
          distances[production] = Number.MAX_VALUE;
        }
      })
    );
    const deliveryOption = deliverySheet.find((row) => row[0] === deliveryMethod);


    //console.log(deliveryOption)

    // Проверяем, что deliveryOption найден, иначе предупреждаем и возвращаем
    if (!deliveryOption) {
      console.error(`Метод доставки '${deliveryMethod}' не найден в deliverySheet.`);
      return; // Пропускаем дальнейшую обработку
    }

    const [maxCapacity, maxVolume, minCharge, costPerKm] = deliveryOption
      .slice(1)
      .map((value) => parseFloat(value.toString().replace(",", ".")));


    const nearestDistanceOutsideKAD = await isPointInsideKAD(
      destination,
      nearestCoords,
      nearestDistance
    );
    const deliveryCostPerTrip =
      parseInt(minCharge) + costPerKm * nearestDistanceOutsideKAD;
      
    const numOfTripsMass = Math.ceil(totalWeight / maxCapacity);
    const numOfTripsVolume = Math.ceil(totalVolume / maxVolume);

    const numOfTrips = Math.max(numOfTripsMass, numOfTripsVolume);
    const tripCounts = Array(suitableProductions.length).fill(numOfTrips);

    const deliveryCosts = tripCounts.map(
      () => deliveryCostPerTrip * numOfTrips
    );

    //console.log("distances ", distances)

    const resultTable = Object.entries(materialCosts).map(
      ([production, cost], index) => {
        return [
          production,
          formatNumberWithSpaces(distances[production]),
          formatNumberWithSpaces(cost),
          formatNumberWithSpaces(deliveryCosts[index]),
          formatNumberWithSpaces(tripCounts[index]),
          formatNumberWithSpaces(cost + deliveryCosts[index]),
          formatDate(updateDates[production]),
        ];
      }
    );

    materialCosts = allProductions.reduce((acc, production) => {
      const items = productList
        .map(([productName, productQuantity]) => {
          const priceColumn = paymentMethod === "cash" ? 4 : 5;
          const stockEntry = stockSheet.find(
            (row) => row[1] === production && row[2] === productName
          );

          if (stockEntry) {
            const price = parseFloat(
              stockEntry[priceColumn].replace(/\s/g, "")
            );
            return { productName, price, quantity: productQuantity };
          } else {
            return null;
          }
        })
        .filter((item) => item !== null);

      if (items.length > 0) {
        acc[production] = items;
      }

      return acc;
    }, {});

    const optimalRoute = await findOptimalProductionRoute(
      allProductions,
      productList,
      materialCosts,
      numOfTrips,
      updateDates,
      deliveryCostPerTrip,
      nearestDistanceOutsideKAD
    );
    printCachedDistances();
    return {
      data: sortArray(resultTable),
      kadDistance: nearestDistanceOutsideKAD,
      addressesProductions,
      optimalData: optimalRoute, // Добавляем данные об оптимальном маршруте
      optimalDeliveryMethod: deliveryMethod,
    };
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}


function generateCacheKey(coord1, coord2) {
  return [coord1, coord2].sort().join("-"); // Сортируем координаты и соединяем их через "-"
}


async function getCachedDistance(coord1, coord2) {
  const key = generateCacheKey(coord1, coord2); // Используем функцию для генерации ключа
  //console.log(`Проверка кэша для ключа: ${key}`);
  
  if (cachedDistances[key] !== undefined) {
    //console.log(`Использование закэшированного расстояния для ключа: ${key}, значение: ${cachedDistances[key]}`);
    return cachedDistances[key];
  }

 //console.log(`Расстояние для ключа ${key} не найдено в кэше. Выполняется запрос к серверу...`);
  const distance = await fetchDistance(coord1, coord2); // Запрос к серверу
  cachedDistances[key] = distance; // Сохранение результата в кэш
  //console.log(`Сохранение расстояния в кэш для ключа: ${key}, значение: ${distance}`);
  
  return distance;
}



async function nearestExitKad(pointsKadSheet, destination) {
  try {
    let nearestExit = {
      distance: Number.MAX_VALUE,
      coordinates: null,
    };
    //console.log("Начинается поиск ближайшего выхода на КАД для точки назначения:", destination);

    // Используем кэш для вычисления расстояний
    await Promise.all(
      pointsKadSheet.slice(1).map(async (row) => {
        const coord = row[0];
        //console.log(`Вычисление расстояния от точки ${coord} до ${destination}`);
        const distance = await getCachedDistance(coord, destination); // Используем getCachedDistance

        if (isNaN(distance)) {
          console.warn(`Некорректное значение расстояния для координаты ${coord}: ${distance}`);
          return Number.MAX_VALUE; // Возвращаем максимальное значение, чтобы исключить его из расчета минимального расстояния
        }

        if (distance < nearestExit.distance) {
          //console.log(`Обновление ближайшего выхода на КАД: от точки ${coord} с расстоянием ${distance}`);
          nearestExit = {
            distance: distance,
            coordinates: coord,
          };
        }

        return distance;
      })
    );

    if (nearestExit.distance === Infinity) {
      console.error("Все расстояния равны бесконечности, что-то пошло не так.");
      return {
        distance: Number.MAX_VALUE,
        coordinates: null,
      };
    }

    //console.log("Ближайший выход на КАД найден:", nearestExit);
    return nearestExit;
  } catch (error) {
    console.error("Ошибка в функции nearestExitKad:", error);
    return {
      distance: Number.MAX_VALUE,
      coordinates: null,
    };
  }
}

// Функция для фильтрации производств, которые могут произвести все продукты из списка
function filterProductionsByAllProducts(stockSheet, targetProducts) {
  const productionMap = stockSheet.reduce((map, row) => {
    const production = row[1];
    const product = row[2];
    if (!map[production]) map[production] = [];
    map[production].push(product);
    return map;
  }, {});

  return Object.keys(productionMap).filter((production) =>
    targetProducts.every((product) =>
      productionMap[production].includes(product)
    )
  );
}

// Функция для сортировки массива по предпоследнему элементу (общей стоимости)
function sortArray(array) {
  return array.sort((a, b) => a[a.length - 2] - b[a.length - 2]);
}

function formatNumberWithSpaces(num) {
  if (num == null) {
    return "0"; // Или другой подходящий вариант
  }
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function formatDate(date) {
  if (!date || isNaN(date.getTime())) {
    return "Дата не определена";
  }
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
}


async function listPrices(productNames, paymentMethod, productionName) {
  try {
    if (cachedData === null) {
      cachedData = await fetchDataOS();
    }
    const data = cachedData;
    const stockSheet = data.stockSheet;
    const priceColumn = paymentMethod === "cash" ? 4 : 5;

    // Получаем список цен на продукты
    const prices = productNames.map((productName) => {
      const productRow = stockSheet.find(
        (row) => row[1] === productionName && row[2] === productName
      );
      if (!productRow) {
        console.error(
          `Продукт ${productName} не найден в производстве ${productionName}`
        );
        return null; // Вернуть null, если продукт не найден, чтобы избежать остановки выполнения
      }
      return productRow[priceColumn];
    });

    // Используем глобальную переменную, если необходимо
    const cheapestMethod = globalCheapestMethod;

    // Возвращаем объект с ценами и методом доставки
    return {
      prices,
      cheapestMethod,
    };
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}



function printCachedDistances() {
 // console.log("Содержимое кэша расстояний (cachedDistances):");
  Object.entries(cachedDistances).forEach(([key, value]) => {
   // console.log(`Ключ: ${key}, Значение: ${value}`);
  });
}






module.exports = {
  getProductionData,
  listPrices,
  nearestExitKad,
};
