import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { fetchIdsOS, sendDataToBitrix24, fetchIdsJBI, fetchIdsJBIMoscow} from '../fetches';

const BASE_URL = process.env.REACT_APP_BASE_URL_OS;

// Стилизованные компоненты
const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const Content = styled.div`
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;
  max-width: 600px;
  width: 100%;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  position: relative;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 20px;
`;

const Body = styled.div`
  padding-bottom: 20px;
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const Button = styled.button`
  padding: 0.5rem 1rem;
  font-size: 1rem;
  cursor: pointer;
  border: none;
  border-radius: 4px;
  margin-left: 10px;
  transition: background-color 0.3s ease;

  &.primary {
    background-color: var(--BLUE_BUTTON);
    color: white;
  }

  &.primary:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  &.primary:hover:enabled {
    background-color: var(--BLUE_BUTTON_DARK);
  }
`;

const CloseButton = styled.button`
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  color: #888;
  transition: color 0.3s ease;

  &:hover {
    color: #555;
  }
`;

const ModalFormBitrix6OS = ({ isModalBitrixVisible, onClose, dealId, modalFormData, onSuccess,  selectedOption}) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchIds =
  selectedOption === "Общестрой"
    ? fetchIdsOS
    : selectedOption === "ЖБИ"
    ? fetchIdsJBI
    : fetchIdsJBIMoscow;

  useEffect(() => {
    if (isModalBitrixVisible && modalFormData.length > 0) {
      console.log('Наименования товаров:', modalFormData[0][0]);
    }
  }, [isModalBitrixVisible, modalFormData]);

  useEffect(() => {
    if (!isModalBitrixVisible) {
      setIsSubmitting(false);
      setIsLoading(false);
    }
  }, [isModalBitrixVisible]);

  const handleSubmit = async () => {
    setErrorMessage('');
    setSuccessMessage('');
    setIsSubmitting(true);
    setIsLoading(true);

    try {
      const numericDealId = parseInt(dealId, 10);
      if (isNaN(numericDealId)) {
          throw new Error('Некорректный ID сделки.');
      }

      // Получение ID продуктов
      const searchStrings = modalFormData.map(item => item[0]);
      const productIds = await fetchIds(searchStrings);

      const productRows = modalFormData.map((item, index) => ({
        PRODUCT_ID: parseInt(productIds[index], 10), // Преобразование в число
        PRICE: parseInt(item[2], 10),
        QUANTITY: parseInt(item[1], 10),
      }));

      // Логирование данных перед отправкой
    console.log('Отправляемые данные:', {
      numericDealId,
      productRows,
    });
    
      const response = await sendDataToBitrix24(numericDealId, productRows);
      console.log('Data sent successfully', response);
      setSuccessMessage(`Данные о товарах для сделки с ID ${numericDealId} успешно сохранены.\nДля отображения изменений обновите страницу (после обновления данные в калькуляторе будут сброшены)`);

      setIsLoading(false);
      setTimeout(() => {
        onSuccess();
      }, 3000);
    } catch (error) {
      console.error('Error in handleSubmit:', error);
      setErrorMessage(error.message || 'Ошибка при сохранении данных.');
      setIsSubmitting(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (window.BX24) {
      window.BX24.init(() => {
        // Получаем текущие размеры окна
        const width = window.innerWidth; // Текущая ширина
        const height = window.innerHeight + 2000; // Добавляем 200 пикселей к высоте
  
        // Устанавливаем новые размеры окна IFRAME
        window.BX24.resizeWindow(width, height);
      });
    }
  }, []);


  useEffect(() => {
    if (isModalBitrixVisible) {
      handleSubmit();
    }
  }, [isModalBitrixVisible, dealId]);

  if (!isModalBitrixVisible) {
    return null;
  }

  return (
    <Overlay>
      <Content>
        <Header>
          <h2>Обработка данных</h2>
          <CloseButton onClick={onClose}>&times;</CloseButton>
        </Header>
        <Body>
          {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
          {successMessage && <p style={{ color: 'green' }}>{successMessage}</p>}
          {isLoading && <p>Сохранение данных...</p>}
        </Body>
        <Footer>
          {!isLoading && (
            <Button className="primary" onClick={onClose}>
              Закрыть
            </Button>
          )}
        </Footer>
      </Content>
    </Overlay>
  );
};

export default ModalFormBitrix6OS;