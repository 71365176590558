import styled from "styled-components";

const StyledFormGroup = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 1.25rem;
  flex-direction: row;
  flex-wrap: nowrap; /* Элементы не переносятся на больших экранах */
  gap: 20px;

  @media (max-width: 768px) {
    flex-wrap: wrap; /* Разрешаем перенос на маленьких экранах */
    gap: 10px; /* Уменьшаем расстояние между элементами */
  }
`;



const Section = styled.section`
  display: ${({ $isVisible }) => ($isVisible ? "block" : "none")};
  padding: 20px;
  margin: 1.25rem 5vw;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
`;

const HeaderContainer = styled.div`
  margin-bottom: 1rem;
`;

const Header = styled.h2`
  margin: 0;
`;

const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StyledText = styled.p`
  margin: 0;
`;

const Label = styled.label`
  display: inline-flex;
  margin: 0.5rem 0;
  font-weight: bold;
  font-size: 1rem; /* Размер шрифта */
  color: #333; /* Цвет текста */
  margin-right: 10px; /* Отступ справа */
  align-items: center;
  margin-bottom: 0.5rem;
`;

const Input = styled.input`
  width: 100%;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-radius: 3px;
  font-size: 0.875rem;
  box-sizing: border-box;
`;

const ErrorText = styled.p`
  color: red;
  font-weight: bold;
`;


const ButtonContainer = styled.div`
  margin-top: 1.25rem;
`;

const Spacer = styled.div`
  height: 120px;
`;



// Базовый компонент кнопки
const BaseButton = styled.button`
  padding: 8px 12px;
  margin: 0;
  border: none;
  border-radius: var(--BORDER_RADIUS);
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 400px;

  color: white;
  background-color: ${({ bgColor }) => bgColor || "gray"};
  &:hover {
    background-color: ${({ hoverColor }) => hoverColor || "darkgray"};
  }
`;



const GreenButton = styled.button`
  background-color: var(--GREEN_BUTTON);
  color: white;
  padding: 15px 25px; /* Увеличены отступы для удобства */
  margin-top: 10px;
  border: none;
  border-radius: var(--BORDER_RADIUS);
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center; /* Центрирование текста */
  line-height: 1.2; /* Оптимальный интервал для текста */
  width: 100%; /* Адаптивная ширина */

  &:hover {
    background-color: var(--GREEN_BUTTON_DARK);
  }

  @media (min-width: 768px) {
    width: auto; /* Для больших экранов возвращаем фиксированный размер */
  }
`;

const RedButton = styled(BaseButton)`
  background-color: var(--RED_BUTTON);
  &:hover {
    background-color: var(--RED_BUTTON_DARK);
  }
`;

const BlueButton = styled(BaseButton)`
  background-color: var(--BLUE_BUTTON);
  &:hover {
    background-color: var(--BLUE_BUTTON_DARK);
  }
`;

const PurpleButton = styled(BaseButton)`
  background-color: var(--PURPLE_BUTTON);
  &:hover {
    background-color: var(--PURPLE_BUTTON_DARK);
  }
`;


const Icon = styled.i`
  font-size: ${({ size }) => size || "1.2em"}; /* Размер иконки, по умолчанию 1.2em */
  line-height: 1; /* Убираем лишние отступы */
  display: inline-block; /* Делаем элемент блочным */
  color: ${({ color }) => color || "inherit"}; /* Цвет, наследуемый или заданный */
  transition: transform 0.3s ease; /* Анимация */
  
  &:hover {
    transform: scale(${({ hoverScale }) => hoverScale || 1.1}); /* Увеличение при наведении */
  }
`;

const ButtonGroupWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 0.625rem;
`;

const RadioGroup = styled.div`
  display: flex;
  align-items: center;
  justify-content: center; /* Центрирование элементов по горизонтали */
  gap: 0.625rem;
  margin-bottom: 0.625rem;
  flex-wrap: wrap; /* Позволяет элементам переноситься на новую строку при уменьшении ширины */
`;



const CoordinatesAndMapContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 10px;

  @media (min-width: 768px) {
    flex: 1 1 45%;
  }
`;

const Select = styled.select`
  width: 100%; /* Занимает всю ширину родительского контейнера */
  max-width: 100%; /* Предотвращает выход за границы */
  padding: 0.5rem;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
`;

const SubBlock = styled.div`
  flex: 1 1 calc(25% - 20px); /* Элементы занимают 1/3 ширины с учетом gap */
  max-width: calc(25% - 20px); /* Ограничиваем ширину */
  display: flex;
  flex-direction: column;
  align-items: center; /* Центрируем содержимое по горизонтали */


  @media (max-width: 768px) {
    flex: 1 1 100%; /* На узких экранах элементы занимают всю ширину */
    max-width: 100%;
  }
`;

const FormGroup = styled.div`
  display: flex; /* Устанавливаем Flexbox */
  flex-wrap: wrap; /* Позволяем элементам переноситься на новую строку */
  justify-content: flex-start; /* Выравнивание элементов слева */
  align-items: flex-start; /* Выравнивание элементов по верхнему краю */
  gap: 20px; /* Расстояние между элементами */
  margin-bottom: 0.25rem; /* Нижний отступ для контейнера */
`;

const ResizableTh = styled.th`
  position: relative;
  padding-right: 15px; /* Для удобства выделения места для курсора */
  min-width: 50px; /* Минимальная ширина столбца */
  max-width: 400px; /* Максимальная ширина столбца */
  overflow: hidden; /* Чтобы содержимое не выходило за границы */
`;

const ResizeHandle = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  width: 5px;
  height: 100%;
  cursor: ew-resize;
  background-color: #ddd;
`;


const TableContainer = styled.div`
  max-height: 400px;
  overflow-y: auto;
  position: relative;
  z-index: 1;
`;

const Table = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin: 20px 0;
  table-layout: fixed;
  height: auto;

  th,
  td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap; /* По умолчанию перенос запрещён */
  }

  th {
    background-color: #f2f2f2;
    text-align: center;
    word-wrap: break-word; /* Разрешаем перенос слов */
    word-break: break-word; /* Переносим длинные слова */
    white-space: normal; /* Разрешаем перенос строк */
  }

  td {
    word-wrap: break-word; /* Разрешаем перенос слов */
    word-break: break-word; /* Переносим длинные слова */
    white-space: normal; /* Разрешаем перенос строк */
  }

  /* Генерация ширины столбцов */
  ${({ widths = [] }) =>
    widths.length > 0 &&
    widths.map(
      (width, index) => `
      th:nth-child(${index + 1}),
      td:nth-child(${index + 1}) {
        width: ${width}%;
      }
    `
    )}

  /* Адаптивность для малых экранов */
  @media (max-width: 768px) {
    th,
    td {
      font-size: 12px;
      padding: 6px;
    }
  }

  @media (max-width: 480px) {
    th,
    td {
      font-size: 10px;
      padding: 4px;
      display: block;
      width: 100%;
      white-space: normal; /* Разрешаем перенос строк */
    }
  }
`;




const styledComponents = {
    Section,
    HeaderContainer,
    Header,
    FlexContainer,
    StyledText,
    Input,
    ErrorText,
    ButtonContainer,
    Spacer,
    StyledFormGroup,
    Label,
    GreenButton,
    BlueButton,
    ButtonGroupWrapper,
    RadioGroup,
    Select,
    CoordinatesAndMapContainer,
    SubBlock, 
    FormGroup,
    ResizableTh,
    ResizeHandle,
    Table,
    TableContainer,
    RedButton,
    Icon,
    PurpleButton
  };
  
  export default styledComponents;