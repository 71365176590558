import React from 'react';
import styled from 'styled-components';

// Стили для контейнера заголовка
const StyledHeader = styled.header`
  margin: 0;
  padding: 1.25rem; /* Отступы внутри контейнера */
  text-align: center; /* Выравнивание текста по центру */
`;

// Стили для текста заголовка
const StyledH1 = styled.h1`
  font-size: 1.5rem; /* Размер шрифта */
  margin: 0; /* Убираем отступы вокруг */
`;

const Header = ({ selectedOption }) => (
  <StyledHeader>
    <StyledH1>Калькулятор {selectedOption}</StyledH1>
  </StyledHeader>
);

export default Header;
