import React from "react";
import generateTexts from "./texts";

const TextLabel = ({
  labelKey,
  clientName = "имя клиента",
  variables = {},
  items = [], // Добавляем поддержку списка товаров
  className = "",
  style = {},
}) => {
  const texts = generateTexts(clientName, variables); // Генерируем тексты

  // Проверяем, если ключ "startMany", вызываем функцию, иначе просто возвращаем текст
  const rawText =
    labelKey === "startMany"
      ? texts.startMany(items) // Для startMany передаём список товаров
      : texts[labelKey] || "Текст не найден";

  return (
    <span
      className={className}
      style={style}
      dangerouslySetInnerHTML={{ __html: rawText }} // Вставляем HTML
    />
  );
};

export default TextLabel;
