import React, { useState } from "react";

const ToggleVisibility = ({ buttonComponent: ButtonComponent, buttonProps = {}, children }) => {
  const [isVisible, setIsVisible] = useState(false);

  const toggleVisibility = () => {
    setIsVisible((prev) => !prev);
  };

  return (
    <div>
      {ButtonComponent ? (
        <ButtonComponent onClick={toggleVisibility} {...buttonProps} />
      ) : (
        <button onClick={toggleVisibility} {...buttonProps}>
          Toggle
        </button>
      )}
      {isVisible && <div>{children}</div>}
    </div>
  );
};

export default ToggleVisibility;
