import React, { useEffect, useState, useRef } from "react";
import styled from "styled-components";
import TooltipWrapper from "../additionalComponents/TooltipWrapper";
import styledComponents from "../additionalComponents/StyledComponents";
import useFetchProductionData from "../additionalComponents/useFetchProductionData";

const { Section, Table } = styledComponents;

const { getProductionData } = require("../backendOS");
const { getProductionDataJBI } = require("../backendJBI");
const { getProductionDataJBIMoscow } = require("../backendJBIMoscow");

const HighlightColumn = styled.td`
  color: #551a8b;
  transition: color 0.3s ease;

  &:hover {
    color: #ff0000;
  }
`;

const TableRow = styled.tr`
  cursor: pointer; /* Указывает, что строка интерактивная */
  transition: background-color 0.3s ease; /* Плавное изменение цвета */

  &:hover {
    background-color: #f0f0f0; /* Цвет фона при наведении */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Тень для выделения */
  }
`;

const DeliveryMethodText = styled.p`
  margin-top: 20px;
  font-size: 17px; /* Увеличенный шрифт */
  color: #ff0000; /* Красный цвет */
  font-weight: bold; /* Жирный шрифт */
`;

const StyledText = styled.span`
  font-size: 14px;
  font-weight: normal;
  margin-bottom: 20px;
  color: #000000;
  font-family: Arial, sans-serif;
  margin-left: 20px;
  position: relative;
`;

const OptimalDataContainer = styled.div`
  margin-top: 20px;
  padding: 15px;
  background-color: #e0f7e0; /* Soft green background color */
  border: 1px solid #ddd;
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease, box-shadow 0.3s ease;

  &:hover {
    background-color: #d2ecd2; /* Slightly darker green on hover */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
  }
`;

const OptimalDataContent = styled.div`
  flex: 3;
  display: flex;
  flex-direction: column;
`;

const OptimalDataTitle = styled.h3`
  margin-top: 0;
  margin-bottom: 10px;
  font-size: 1.25rem;
  color: #333;
  font-weight: 600;
  border-bottom: 2px solid #ccc;
  padding-bottom: 5px;
`;

const DataItem = styled.p`
  margin: 5px 0;
  font-size: 0.95rem;
  color: #555;

  & > strong {
    color: #333;
    font-weight: 500;
    font-family: Arial, sans-serif;
  }
`;

const BoldDataItem = styled(DataItem)`
  font-weight: bold; /* Добавляем жирный шрифт */
`;

const ProductSourceContainer = styled.div`
  margin-top: 10px;
  padding: 10px;
  border-top: 1px solid #ddd;
`;

const ProductSourceItem = styled.div`
  margin: 8px 0;
  display: flex;
  align-items: center;

  &::before {
    content: "•";
    color: #888;
    margin-right: 8px;
    font-size: 1.2rem;
  }

  & > strong {
    margin-right: 8px;
    color: #000;
  }

  & > span {
    color: #555;
    font-style: italic;
  }
`;

const RadioButtonWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;

  & > input {
    margin-right: 12px; /* Увеличиваем расстояние между кружком и текстом */
  }

  & > label {
    font-size: 0.95rem;
    color: #555;
  }
`;

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
  font-size: 1.2rem;
  color: #555;
`;

const CostComparison3OS = ({
  isCostComparisonVisible,
  selectedDeliveryOption,
  paymentMethod,
  clientCoordinates,
  totalWeight,
  totalVolume,
  namesAndQuantities,
  toggleCommercialOfferVisibility,
  onSelectedProduction,
  isSelfPickup,
  selectedOption, // Добавляем сюда selectedOption как пропс
}) => {
  const [sortDirection, setSortDirection] = useState("asc");
  const [selectedIndex, setSelectedIndex] = useState(null);
  const [selectedOptimal, setSelectedOptimal] = useState(false);
  const [hoveredProduction, setHoveredProduction] = useState(null); // eslint-disable-line
  const previousProductionName = useRef(null);
  const previousIndex = useRef(null);

  const getProductionDataFunction =
    selectedOption === "Общестрой"
      ? getProductionData
      : selectedOption === "ЖБИ"
      ? getProductionDataJBI
      : selectedOption === "ЖБИ Москва"
      ? getProductionDataJBIMoscow
      : () => {
          throw new Error(`Неизвестный selectedOption: ${selectedOption}`);
        };

  const sortByTotalSum = (data, direction) => {
    // Проверяем, есть ли хотя бы одна запись с ненулевой общей суммой
    const hasNonZeroTotalSum = data.some(
      (row) => parseFloat(row[5].replace(/\s/g, "")) > 0
    );

    if (hasNonZeroTotalSum) {
      // Сортируем по общей сумме
      return [...data].sort((a, b) => {
        const aValue = parseFloat(a[5].replace(/\s/g, ""));
        const bValue = parseFloat(b[5].replace(/\s/g, ""));
        return direction === "asc" ? aValue - bValue : bValue - aValue;
      });
    } else {
      // Если общая сумма везде 0, сортируем по сумме материалов (предположим, это a[2])
      return [...data].sort((a, b) => {
        const aValue = parseFloat(a[2].replace(/\s/g, ""));
        const bValue = parseFloat(b[2].replace(/\s/g, ""));
        return direction === "asc" ? aValue - bValue : bValue - aValue;
      });
    }
  };

  const {
    isLoading,
    productionData,
    sortedData,
    kadDistance,
    productionAddresses,
    optimalData,
    optimalDeliveryMethod,
    setSortedData,
    fetchProductionData,
  } = useFetchProductionData({
    getProductionDataFunction,
    sortByTotalSum,
    toggleCommercialOfferVisibility,
  });

  useEffect(() => {
    fetchProductionData({
      isCostComparisonVisible,
      isSelfPickup,
      selectedDeliveryOption,
      paymentMethod,
      clientCoordinates,
      totalWeight,
      totalVolume,
      namesAndQuantities,
      sortDirection,
    });
  }, [
    isCostComparisonVisible,
    isSelfPickup,
    selectedDeliveryOption,
    paymentMethod,
    clientCoordinates,
    totalWeight,
    totalVolume,
    namesAndQuantities,
    sortDirection,
  ]);

  // Сбрасываем выбор при изменении списка товаров или способа доставки
  useEffect(() => {
    setSelectedIndex(null);
    setSelectedOptimal(false);
    toggleCommercialOfferVisibility(false);
  }, [
    namesAndQuantities,
    selectedDeliveryOption,
    toggleCommercialOfferVisibility,
  ]);

  useEffect(() => {
    if (sortedData.length > 0 && selectedIndex !== null) {
      const currentProductionName = sortedData[selectedIndex]?.[0];

      if (
        previousProductionName.current &&
        previousIndex.current === selectedIndex &&
        previousProductionName.current !== currentProductionName
      ) {
        setSelectedIndex(null);
        toggleCommercialOfferVisibility(false);
      }

      previousProductionName.current = currentProductionName;
      previousIndex.current = selectedIndex;
    }

    if (selectedIndex === null && !selectedOptimal) {
      toggleCommercialOfferVisibility(false);
    }
  }, [
    sortedData,
    selectedIndex,
    selectedOptimal,
    toggleCommercialOfferVisibility,
  ]);

  const toggleSortDirection = () => {
    const newDirection = sortDirection === "asc" ? "desc" : "asc";
    setSortDirection(newDirection);
    setSortedData(sortByTotalSum(productionData, newDirection));
  };

  const handleRowSelect = (index) => {
    setSelectedIndex(index);
    setSelectedOptimal(false);
    handleDisplayKP(index);
  };

  const handleSelectOptimal = () => {
    setSelectedIndex(null);
    setSelectedOptimal(true);
    handleDisplayOptimalKP();
  };

  const handleDisplayKP = async (index) => {
    if (index >= 0 && index < sortedData.length) {
      const selectedProductionData = [
        sortedData[index][0],
        sortedData[index][3],
        sortedData[index][4],
        sortedData[index][6],
      ];

      onSelectedProduction(selectedProductionData);
      toggleCommercialOfferVisibility(true);

      await new Promise((resolve) => setTimeout(resolve, 0));

      const section = document.getElementById("commercial-offer");
      if (section) {
        section.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };

  const handleDisplayOptimalKP = async () => {
    if (optimalData) {
      let totalDeliveryCost;

      // Изменяем логику расчета доставки в зависимости от selectedOption
      if (selectedOption === "Общестрой") {
        // Логика для Общестрой
        totalDeliveryCost =
          optimalData.numOfTrips * optimalData.deliveryCostPerTrip +
          optimalData.additionalCost;
      } else {
        // Если ни одно из условий не подошло, просто оставляем как есть
        totalDeliveryCost = optimalData.totalDeliveryCost;
      }

      const selectedProductionData = [
        "Оптимальный маршрут",
        totalDeliveryCost,
        optimalData.numOfTrips,
        optimalData.updateDates,
        optimalData.productSources,
      ];

      onSelectedProduction(selectedProductionData);
      toggleCommercialOfferVisibility(true);

      await new Promise((resolve) => setTimeout(resolve, 0));

      const section = document.getElementById("commercial-offer");
      if (section) {
        section.scrollIntoView({ behavior: "smooth", block: "start" });
      }
    }
  };

  const formatNumber = (num) => {
    if (typeof num === "number") {
      return num.toLocaleString("ru-RU");
    } else {
      console.error("Ошибка: Ожидалось число, но получено:", num);
      return "0"; // Возвращаем строку с числом 0 по умолчанию или любое значение, которое нужно по логике
    }
  };

  const renderProductSources = (productSources) => {
    return Object.entries(productSources).map(
      ([productName, sourceData], index) => (
        <ProductSourceItem key={index}>
          <strong>{productName}:</strong>
          <span>
            {sourceData.production} (Дата обновления: {sourceData.updateDate})
          </span>
        </ProductSourceItem>
      )
    );
  };

  return (
    <Section $isVisible={isCostComparisonVisible}>
      <h2>
        Сравнение стоимости производств
        {kadDistance !== null && (
          <StyledText>Расстояние от КАД: {kadDistance} км</StyledText>
        )}
      </h2>
      {isLoading ? (
        <LoadingContainer>Загрузка данных...</LoadingContainer>
      ) : (
        <Table
          widths={isSelfPickup ? [25, 8, 10, 8, 8] : [25, 6, 8, 10, 8, 8, 8]}
        >
          <thead>
            <tr>
              <th>Производство</th>
              {!isSelfPickup && <th>Расстояние, км</th>}
              {!isSelfPickup && <th>Сумма материалов</th>}
              {selectedOption !== "Общестрой" &&
                sortedData.some((row) => row[7]) && <th>Описание</th>}
              <th>Дата обновления цен</th>
              {!isSelfPickup && <th>Стоимость доставки</th>}
              {!isSelfPickup && <th>Кол-во рейсов</th>}
              <th onClick={toggleSortDirection} style={{ cursor: "pointer" }}>
                Общая сумма {sortDirection === "asc" ? "▲" : "▼"}
              </th>
            </tr>
          </thead>
          <tbody id="table-body">
            {sortedData.map((row, index) => (
              <TableRow
                key={index}
                onClick={() => handleRowSelect(index)} /* Обработка клика */
              >
                <HighlightColumn>
                  <TooltipWrapper
                    text={productionAddresses[row[0]] || "Адрес не найден"}
                  >
                    {row[0]}
                  </TooltipWrapper>
                </HighlightColumn>
                {!isSelfPickup && <td>{row[1]}</td>} {/* Расстояние */}
                {!isSelfPickup && <td>{row[2]}</td>} {/* Сумма материалов */}
                {selectedOption !== "Общестрой" &&
                  row[7] && ( // Проверка на пустоту в row[7]
                    <td>
                      <span
                        style={{ fontStyle: "italic" }} // Устанавливаем курсивный стиль
                        dangerouslySetInnerHTML={{
                          __html: row[7]
                            ? row[7]
                                .split("\n") // Разделяем строку по символу новой строки
                                .map((line) => `${line}`) // Форматируем строки
                                .join("<br />") // Соединяем строки с <br />
                            : "Нет данных",
                        }}
                      />
                    </td>
                  )}
                <td>{row[6]}</td> {/* Дата обновления цен */}
                {!isSelfPickup && <td>{row[3]}</td>} {/* Стоимость доставки */}
                {!isSelfPickup && <td>{row[4]}</td>} {/* Кол-во рейсов */}
                <td>
                  {selectedOption !== "Общестрой" && isSelfPickup
                    ? row[2]
                    : row[5]}
                </td>
              </TableRow>
            ))}
          </tbody>
        </Table>
      )}
      {!isSelfPickup &&
        optimalData &&
        optimalData.mainProduction &&
        selectedOption === "Общестрой" && (
          <OptimalDataContainer onClick={handleSelectOptimal}>
            <OptimalDataContent>
              <OptimalDataTitle>Оптимальный маршрут</OptimalDataTitle>
              <DataItem>
                <strong>Основное производство:</strong>{" "}
                {optimalData.mainProduction}
              </DataItem>
              <DataItem>
                <strong>Дополнительные производства:</strong>{" "}
                {optimalData.additionalProductions.join(", ") || "нет"}
              </DataItem>
              <DataItem>
                <strong>Сумма материалов:</strong>{" "}
                {formatNumber(optimalData.totalMaterialCost)}
              </DataItem>
              <DataItem>
                <strong>Дата обновления цен:</strong> {optimalData.updateDates}
              </DataItem>
              <DataItem>
                <strong>Стоимость доставки:</strong>{" "}
                {formatNumber(optimalData.totalDeliveryCost)}
              </DataItem>
              <DataItem>
                <strong>Количество рейсов:</strong> {optimalData.numOfTrips}
              </DataItem>
              <BoldDataItem>
                <strong>Общая сумма:</strong>{" "}
                {formatNumber(optimalData.totalCost)}
              </BoldDataItem>
              <ProductSourceContainer>
                <DataItem>
                  <strong>С какого производства берем каждый товар:</strong>
                </DataItem>
                {renderProductSources(optimalData.productSources)}
              </ProductSourceContainer>
            </OptimalDataContent>
            <RadioButtonWrapper>
              <input
                type="radio"
                name="selectedRow"
                checked={selectedOptimal}
                onChange={handleSelectOptimal}
                readOnly
              />
              <label>Выбрать</label>
            </RadioButtonWrapper>
          </OptimalDataContainer>
        )}
      {/* Добавляем надпись с информацией о способе доставки */}
      {optimalDeliveryMethod && (
        <DeliveryMethodText>
          *Стоимость доставки указана для способа: {optimalDeliveryMethod}
        </DeliveryMethodText>
      )}
    </Section>
  );
};

export default CostComparison3OS;
