import React, { Component } from "react";
import HeaderOS from "../componentsOS/headerOS";
import Choice0OS from "../componentsOS/choice0OS";
import BasicInfo1OS from "../componentsOS/basicInfo1OS";
import Products2OS from "../componentsOS/products2OS";
import CostComparison3OS from "../componentsOS/costComparison3OS";
import CommercialOffer4OS from "../componentsOS/commercialOffer4OS";
import ModalFormBitrix6OS from "../componentsOS/modalFormBitrix6OS";
import TestComponent from "../componentsOS/test";
import { fetchAllDataOS, fetchAllDataJBI, fetchAllDataJBIMoscow } from "../fetches";

class AppOS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientName: "имя клиента",
      isProductsSectionVisible: false,
      selectedSubsection: "",
      selectedDeliveryOption: "Оптимальный способ",
      isCostComparisonVisible: false,
      isCommercialOfferVisible: false,
      paymentMethod: "cash",
      clientCoordinates: "",
      totalWeight: "",
      totalVolume: "", // Добавляем состояние для общего объема
      namesAndQuantities: [],
      selectedProduction: [],
      selectedProductionName: "",
      rowCount: 0, // Инициализируем rowCount в state
      modalFormData: null, // Новое состояние для данных, которые передадим в ModalForm5 и Bitrix6
      subsectionOptions: [], // Инициализация subsectionOptions
      canDownload: false, // Новое состояние для управления возможностью скачивания
      isSelfPickup: false, // Новое состояние для обработки "Самовывоза"
      selectedOption: "Общестрой", // Добавляем состояние для выбора "ЖБИ" или "Общестрой"
      isOptionChangeDisabled: false, // Новый флаг для блокировки выбора опций
      dealId: null, // Отдельное состояние для ID сделки
      dealAddress: null, // Отдельное состояние для адреса сделки
      isLoading: true, // Состояние для отображения индикатора загрузки
      analogData: null,  // Состояние для хранения данных о аналогах

    };
  }

  async componentDidMount() {
    try {
      // Выполняем все функции параллельно
      await Promise.all([fetchAllDataOS(), fetchAllDataJBI(), fetchAllDataJBIMoscow()]);
      this.setState({ isLoading: false }); // Завершаем загрузку
    } catch (error) {
      console.error("Ошибка при выполнении fetchAllDataOS, fetchAllDataJBI или fetchAllDataJBIMoscow:", error);
      this.setState({ isLoading: false }); // Завершаем загрузку, даже если произошла ошибка
    }
  }

  handleChoiceChange = (selectedOption) => {
    // Сбрасываем флаг блокировки выбора опций, если координаты не введены
    if (!this.state.isOptionChangeDisabled) {
      this.setState({ selectedOption });
    }
  };

  resetState = () => {
    this.setState({
      selectedSubsection: "",
      isCostComparisonVisible: false,
      isCommercialOfferVisible: false,
      totalWeight: "",
      totalVolume: "",
      namesAndQuantities: [],
      selectedProduction: [],
      selectedProductionName: "",
      rowCount: 0,
      modalFormData: null,
      subsectionOptions: [],
      canDownload: false,
      dealId: null,
      analogData: null,
      isLoading: false, // Сбрасываем индикатор загрузки
    });
  };
  
  

handleAnalogDataChange = (analogData) => {
    // Сохраняем данные о аналогах в состояние
    console.log("Данные об аналогах:", analogData);
    this.setState({ analogData });
};
  
handleDealId = (data) => {
    console.log("готово ID сделки:", data);
    this.setState({ dealId: data }); // Сохраняем только ID в dealId
};

handleDealAddress = (coordinates) => {
  // Извлекаем координаты, которые идут после символа "||"
  console.log("готово Координаты:", coordinates);
  // Сохраняем только координаты в state
  this.setState({ dealAddress: coordinates });
};


handleDealContact = (clientName) => {
  this.setState({ clientName }); // Сохраняем имя клиента в состояние
};


handleSelfPickupChange = (isSelfPickup) => {
  this.setState({ isSelfPickup }, () => {
    this.resetState(); // Сбрасываем состояние после обновления isSelfPickup
  });
};

  

  componentDidUpdate(prevProps, prevState) {
    // Log namesAndQuantities to the console whenever it changes
    if (prevState.namesAndQuantities !== this.state.namesAndQuantities) {
      //console.log('namesAndQuantities:', this.state.namesAndQuantities);
    }
  }

  handleRowCountChange = (count) => {
    this.setState({ rowCount: count });
    //console.log("Number of rows:", count);
  };

  // Метод для открытия/закрытия модального окна
  toggleModalVisibility = () => {
    this.setState((prevState) => ({
      isModalVisible: !prevState.isModalVisible,
    }));
  };

  // Метод для открытия/закрытия модального окна Bitrix
  toggleModalBitrixVisibility = () => {
    this.setState((prevState) => ({
      isModalBitrixVisible: !prevState.isModalBitrixVisible,
    }));
  };

  handleSubsectionChange = (selectedSubsection) => {
    this.setState({
      selectedSubsection,
      isProductsSectionVisible: selectedSubsection !== "",
    });
  };

  handleDeliveryChange = (selectedDeliveryOption) => {
    this.setState({ selectedDeliveryOption });
  };

  handlePaymentChange = (paymentMethod) => {
    this.setState({ paymentMethod });
  };

  handleSelectedProduction = (data) => {
    // Проверяем, отличается ли выбранное производство от текущего
    if (
      this.state.selectedProduction.length > 0 &&
      this.state.selectedProduction[0] !== data[0]
    ) {
      // Очищаем массив selectedProduction
      this.setState({
        selectedProduction: [data],
        selectedProductionName: [data[0]],
      });
    } else {
      // Добавляем в selectedProduction, если это первый выбор или тот же продукт
      this.setState((prevState) => ({
        selectedProduction: [data],
        selectedProductionName: [data[0]],
      }));
    }
  };

  handleOptimalRouteSelection = (optimalRoute) => {
    const selectedProductionData = [
      optimalRoute.mainProduction, // Название основного производства
      optimalRoute.numOfTrips * optimalRoute.deliveryCostPerTrip +
        optimalRoute.additionalCost, // Стоимость доставки
      optimalRoute.numOfTrips, // Количество рейсов
      optimalRoute.updateDates, // Дата обновления цен
      //optimalData.productSources,
    ];
    console.log(selectedProductionData);
    this.setState({
      selectedProduction: [selectedProductionData],
      selectedProductionName: [optimalRoute.mainProduction],
      isCommercialOfferVisible: true, // Отображаем коммерческое предложение сразу после выбора
    });
  };

  handleConvertCoordinates = (clientCoordinates) => {
    // Блокируем возможность смены выбора сразу после ввода координат
    this.setState({
      clientCoordinates,
    });
  };

  handleTotalWeightChange = (totalWeight) => {
    this.setState({ totalWeight });
  };

  handleTotalVolumeChange = (totalVolume) => {
    this.setState({ totalVolume });
  };

  handleNamesAndQuantitiesChange = (namesAndQuantities) => {
    this.setState({ namesAndQuantities });
  };

  handleCommercialOfferVisibility = (isVisible) => {
    this.setState({ isCommercialOfferVisible: isVisible });
  };

  handleCostComparisonVisibility = (isVisible) => {
    this.setState({ isCostComparisonVisible: isVisible });
  };

  toggleProductsSectionVisibility = () => {
    this.setState((prevState) => ({
      isProductsSectionVisible: !prevState.isProductsSectionVisible,
    }));
  };

  handleSaveToPDF = (data) => {
    this.setState({ modalFormData: data });
  };

  handleSuccess = () => {
    // Закрываем модальное окно через 3 секунды
    setTimeout(() => {
      this.setState({ isModalBitrixVisible: false, canDownload: true }); // Обновляем состояния одновременно
    }, 3000);
  };

  render() {
    const {
      clientName,
      isProductsSectionVisible,
      selectedSubsection,
      selectedDeliveryOption,
      isCostComparisonVisible,
      isCommercialOfferVisible,
      paymentMethod,
      clientCoordinates,
      totalWeight,
      totalVolume,
      namesAndQuantities,
      selectedProduction,
      selectedProductionName,
      isModalBitrixVisible,
      modalFormData,
      isSelfPickup,
      selectedOption,
      isOptionChangeDisabled,
      dealId,
      dealAddress,
      isLoading,
      analogData,
    } = this.state;

    if (isLoading) {
      // Показываем индикатор загрузки, пока функция выполняется
      return <div>Загрузка данных...</div>;
    }

    return (
      <div className="AppOS">
        <TestComponent
          isModalBitrixVisible={isModalBitrixVisible}
          modalFormData={modalFormData}
          onDealId={this.handleDealId}
          onDealAddress={this.handleDealAddress}
          onContactData={this.handleDealContact}

        />
        <Choice0OS
          onChoiceChange={this.handleChoiceChange}
          isDisabled={isOptionChangeDisabled} // Блокировка изменения выбора
        />
        <HeaderOS selectedOption={selectedOption} />,
        <BasicInfo1OS
          selectedOption={selectedOption} // Передаем выбранную опцию как пропс
          selectedDeliveryOption={selectedDeliveryOption}
          onDeliveryChange={this.handleDeliveryChange}
          paymentMethod={paymentMethod}
          onPaymentChange={this.handlePaymentChange}
          clientCoordinates={clientCoordinates}
          onCoordinatesChange={this.handleConvertCoordinates}
          setIsProductsSectionVisible={(value) =>
            this.setState({ isProductsSectionVisible: value })
          }
          onSelfPickupChange={this.handleSelfPickupChange}
          dealCoordinates={dealAddress || "Адрес отсутствует"} // Передаем адрес в BasicInfo1OS
        />
        <Products2OS
          key={selectedOption} // При изменении ключа компонент перерисовывается
          selectedOption={selectedOption} // Передаем выбранную опцию как пропс
          isProductsSectionVisible={isProductsSectionVisible}
          onTotalWeightChange={this.handleTotalWeightChange}
          onTotalVolumeChange={this.handleTotalVolumeChange} // Передаем новый обработчик
          onNamesAndQuantitiesChange={this.handleNamesAndQuantitiesChange}
          toggleCostComparisonVisibility={this.handleCostComparisonVisibility}
          toggleCommercialOfferVisibility={this.handleCommercialOfferVisibility}
          onAnalogDataChange={this.handleAnalogDataChange}  // Передаем функцию для получения данных о аналогах
        />
        <CostComparison3OS
          selectedSubsection={selectedSubsection}
          selectedDeliveryOption={selectedDeliveryOption}
          isCostComparisonVisible={isCostComparisonVisible}
          paymentMethod={paymentMethod}
          clientCoordinates={clientCoordinates}
          totalWeight={totalWeight}
          totalVolume={totalVolume}
          namesAndQuantities={namesAndQuantities}
          toggleCommercialOfferVisibility={this.handleCommercialOfferVisibility}
          selectedProduction={selectedProduction}
          onSelectedProduction={this.handleSelectedProduction}
          isSelfPickup={isSelfPickup}
          onOptimalRouteSelect={this.handleOptimalRouteSelection}
          selectedOption={selectedOption} // Передаем выбранную опцию как пропс
        />
        <CommercialOffer4OS          
          clientName={clientName}
          isVisible={isCommercialOfferVisible}
          selectedProduction={selectedProduction}
          selectedProductionName={selectedProductionName}
          namesAndQuantities={namesAndQuantities}
          selectedDeliveryOption={selectedDeliveryOption}
          paymentMethod={paymentMethod}
          setIsModalBitrixVisible={(value) =>
            this.setState({ isModalBitrixVisible: value })
          }
          onSaveToPDF={this.handleSaveToPDF}
          isSelfPickup={isSelfPickup} // Передаем состояние самовывоза
          selectedOption={selectedOption} // Передаем выбранную опцию как пропс
          analogData={analogData}  // Передаем данные о аналогах в CommercialOffer4OS
          totalWeight={totalWeight}
          clientCoordinates={clientCoordinates}
          totalVolume={totalVolume}

        />
        <ModalFormBitrix6OS
          isModalBitrixVisible={isModalBitrixVisible}
          onClose={this.toggleModalBitrixVisibility}
          dealId={dealId}
          modalFormData={modalFormData}
          onSuccess={this.handleSuccess} // Передаем функцию handleSuccess
          selectedOption={selectedOption}
        />
      </div>
    );
  }
}
export default AppOS;