import React, { useState, useEffect, useCallback } from "react";
import { Button } from "@mui/material"; // Импортируем компонент Button
import {
  fetchAnalogsOSKP,
  fetchTooltip,
  fetchAllAnalogsOSKP,
} from "../fetches"; // Импортируем функцию из fetches.js
import TooltipWrapper from "../additionalComponents/TooltipWrapper";
import styledComponents from "../additionalComponents/StyledComponents";
import TextLabel from "../additionalComponents/TextLabel";
import ToggleVisibility from "../additionalComponents/ToggleVisibility";
import pLimit from "p-limit";
import StyledText from "../additionalComponents/StyledText";

const { listPrices } = require("../backendOS");
const { listPricesJBI } = require("../backendJBI");
const { listPricesJBIMoscow } = require("../backendJBIMoscow");
const { getProductionData } = require("../backendOS");
const { getProductionDataJBI } = require("../backendJBI");
const { getProductionDataJBIMoscow } = require("../backendJBIMoscow");

const CommercialOffer4OS = ({
  clientName,
  isVisible,
  selectedProductionName,
  namesAndQuantities,
  selectedProduction,
  selectedDeliveryOption,
  paymentMethod,
  onSaveToPDF,
  setIsModalVisible,
  setIsModalBitrixVisible,
  isSelfPickup,
  selectedOption, // Добавляем selectedOption как пропс
  analogData,
  totalWeight,
  clientCoordinates,
  totalVolume,
}) => {
  const { Section, BlueButton, Table, RedButton, PurpleButton } =
    styledComponents;

  const [discountPercentage, setDiscountPercentage] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const [itemPrices, setItemPrices] = useState([]);
  const [productCosts, setProductCosts] = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  const [cheapestMethod, setCheapestMethod] = useState(null);
  const [isTable1Visible, setIsTable1Visible] = useState(false); //для таблиц сворачивания
  const [isTable2Visible, setIsTable2Visible] = useState(false); //для таблиц сворачивания
  const [popularAnalogs, setPopularAnalogs] = useState({});
  const [tooltipTexts, setTooltipTexts] = useState({});
  const [selectedAnalog, setSelectedAnalog] = useState(null);
  const [tooltipText, setTooltipText] = useState("");
  const [analoguesForEach, setAnaloguesForEach] = useState({}); // Состояние для глобальной переменной

  const [budgetResults, setBudgetResults] = useState([]); // Состояние для результатов budgets
  const [popularResults, setPopularResults] = useState([]); // Состояние для результатов budgets

  const [cheapestBudget, setCheapestBudget] = useState(null);
  const [cheapestPopular, setCheapestPopular] = useState(null);
  const [calculatedPrices, setCalculatedPrices] = useState({});
  const [calculatedPricesPopular, setCalculatedPricesPopular] = useState({});

  const toggleTable1Visibility = () => setIsTable1Visible(!isTable1Visible);
  const toggleTable2Visibility = () => setIsTable2Visible(!isTable2Visible);

  const getListPricesFunction =
    selectedOption === "Общестрой"
      ? listPrices
      : selectedOption === "ЖБИ"
      ? listPricesJBI
      : listPricesJBIMoscow; // Для варианта "ЖБИ Москва"

  const updateCombinedData = useCallback(
    (prices) => {
      const combinedData = namesAndQuantities.map((item, index) => [
        item[0], // Наименование товара
        item[1], // Количество
        prices[index], // Цена
      ]);

      const lastRowPriceElement = document.querySelector(
        "#kp-table-body tr:last-child td:nth-child(5) input"
      );
      const lastRowPrice = lastRowPriceElement
        ? parseFloat(lastRowPriceElement.value)
        : 0;

      selectedProduction.forEach((item, index) => {
        combinedData.push([
          selectedDeliveryOption, // Наименование доставки
          item[2], // Количество
          lastRowPrice, // Использование значения из последней строки
        ]);
      });
    },
    [namesAndQuantities, selectedProduction, selectedDeliveryOption]
  );

  const handleAnalogClick = async (analog) => {
    const text = await generateTooltipText(analog.itemName, analog.quantity);
    setSelectedAnalog(analog.itemName); // Сохраняем выбранный элемент
    setTooltipText(text); // Загружаем текст подсказки для этого аналога
  };

  useEffect(() => {
    const getAllAnalogs = async () => {
      try {
        console.log("naq", namesAndQuantities);

        // Формируем массив обещаний для всех товаров
        const analogPromises = namesAndQuantities.map(
          async ([itemName, quantity, unit]) => {
            // Получаем аналоги для товара
            const analogs = await fetchAllAnalogsOSKP(itemName, quantity);

            console.log(`Товар: ${itemName} (Количество: ${quantity})`);
            console.log("Популярные аналоги:", analogs.popular);
            console.log("Бюджетные аналоги:", analogs.budget);

            return {
              itemName,
              popular: analogs.popular.map((analog) => ({
                name: analog.itemName,
                quantity: analog.quantity,
                unit: unit,
              })),
              budget: analogs.budget.map((analog) => ({
                name: analog.itemName,
                quantity: analog.quantity,
                unit: unit,
              })),
            };
          }
        );

        // Ожидаем все запросы
        const analogsList = await Promise.all(analogPromises);

        // Формируем словарь analoguesForEach
        const newAnaloguesForEach = {};

        analogsList.forEach(({ itemName, popular, budget }) => {
          newAnaloguesForEach[itemName] = {
            populars: popular,
            budgets: budget,
          };
        });

        // Обновляем состояния
        setPopularAnalogs(
          analogsList.reduce((acc, { itemName, popular }) => {
            acc[itemName] = popular;
            return acc;
          }, {})
        );

        setAnaloguesForEach(newAnaloguesForEach);

        // Вывод в консоль
        console.log(
          "Глобальная переменная analoguesForEach:",
          newAnaloguesForEach
        );
      } catch (error) {
        console.error("Ошибка при получении аналогов:", error);
      }
    };

    getAllAnalogs();
  }, [namesAndQuantities]);

  const generateCombinations = (analoguesForEach) => {
    const productKeys = Object.keys(analoguesForEach);

    const combine = (arrays) => {
      if (arrays.length === 0) return [[]];
      const [first, ...rest] = arrays;
      const combinations = combine(rest);
      return first.flatMap((item) =>
        combinations.map((comb) => [item, ...comb])
      );
    };

    const budgetsCombinations = combine(
      productKeys.map((key) => analoguesForEach[key].budgets)
    );

    const popularsCombinations = combine(
      productKeys.map((key) => analoguesForEach[key].populars)
    );

    return {
      budgetsCombinations,
      popularsCombinations,
    };
  };

  const executeForBudgetCombinations = async (combinations) => {
    setBudgetResults([]); // Очистка состояния перед началом новой обработки
    const limit = pLimit(10); // Ограничение на одновременные задачи

    try {
      const selectedOption = "Общестрой";
      const getProductionDataFunction =
        selectedOption === "Общестрой"
          ? getProductionData
          : selectedOption === "ЖБИ"
          ? getProductionDataJBI
          : selectedOption === "ЖБИ Москва"
          ? getProductionDataJBIMoscow
          : () => {
              throw new Error(`Неизвестный selectedOption: ${selectedOption}`);
            };

      const kuda_edem = clientCoordinates || "59.5412777, 30.8773645";
      const forma_oplati = paymentMethod || "cash";
      const sposob_dostavki = selectedDeliveryOption;
      const obshaya_massa = totalWeight;
      const obshiy_volume = totalVolume;

      const results = []; // Локальный массив для хранения результатов

      const fetchDataForCombination = async (combination) => {
        const spisok = combination.map((item) => [
          item.name,
          item.quantity,
          item.unit,
        ]);

        // Запрашиваем данные
        const result = await getProductionDataFunction(
          spisok,
          kuda_edem,
          forma_oplati,
          sposob_dostavki,
          obshaya_massa,
          obshiy_volume
        );

        // Сохраняем результат в локальный массив
        results.push({ combination: spisok, result });
      };

      // Уникальные комбинации
      const uniqueCombinations = Array.from(
        new Set(combinations.map((combination) => JSON.stringify(combination)))
      ).map((combination) => JSON.parse(combination));

      console.log(
        `Количество уникальных комбинаций:`,
        uniqueCombinations.length
      );

      const limitedCombinations = uniqueCombinations.slice(0, 10);

      // Обработка комбинаций
      const promises = limitedCombinations.map((combination) =>
        limit(() => fetchDataForCombination(combination))
      );

      // Выполняем задачи
      await Promise.all(promises);
      setBudgetResults([]); // Очистка состояния перед началом новой обработки
      // После завершения всех запросов обновляем состояние одним вызовом
      setBudgetResults(results);
      setTimeout(1000);
      console.log("Итоговые результаты budget:", results);
      console.log("Итоговые результаты budgetres:", budgetResults);
    } catch (error) {
      console.error(`Ошибка при обработке комбинаций:`, error);
    }
  };

  useEffect(() => {
    if (Object.keys(analoguesForEach).length > 0) {
      handleExecuteAndFindBudget();
    }
  }, [
    analoguesForEach,
    clientCoordinates,
    paymentMethod,
    selectedDeliveryOption,
    totalWeight,
    totalVolume,
  ]);

  useEffect(() => {
    if (Object.keys(analoguesForEach).length > 0) {
      handleExecuteAndFindPopular();
    }
  }, [
    analoguesForEach,
    clientCoordinates,
    paymentMethod,
    selectedDeliveryOption,
    totalWeight,
    totalVolume,
  ]);

  const executeForPopularCombinations = async (combinations) => {
    setPopularResults([]); // Очистка состояния перед началом новой обработки
    const limit = pLimit(10); // Ограничение на одновременные задачи

    try {
      const selectedOption = "Общестрой";
      const getProductionDataFunction =
        selectedOption === "Общестрой"
          ? getProductionData
          : selectedOption === "ЖБИ"
          ? getProductionDataJBI
          : selectedOption === "ЖБИ Москва"
          ? getProductionDataJBIMoscow
          : () => {
              throw new Error(`Неизвестный selectedOption: ${selectedOption}`);
            };

      const kuda_edem = clientCoordinates || "59.5412777, 30.8773645";
      const forma_oplati = paymentMethod || "cash";
      const sposob_dostavki = selectedDeliveryOption;
      const obshaya_massa = totalWeight;
      const obshiy_volume = totalVolume;

      const results = []; // Локальный массив для хранения результатов

      const fetchDataForCombination = async (combination) => {
        const spisok = combination.map((item) => [
          item.name,
          item.quantity,
          item.unit,
        ]);

        // Запрашиваем данные
        const result = await getProductionDataFunction(
          spisok,
          kuda_edem,
          forma_oplati,
          sposob_dostavki,
          obshaya_massa,
          obshiy_volume
        );

        // Сохраняем результат в локальный массив
        results.push({ combination: spisok, result });
      };

      // Уникальные комбинации
      const uniqueCombinations = Array.from(
        new Set(combinations.map((combination) => JSON.stringify(combination)))
      ).map((combination) => JSON.parse(combination));

      console.log(
        `Количество уникальных комбинаций:`,
        uniqueCombinations.length
      );

      const limitedCombinations = uniqueCombinations.slice(0, 10);

      // Обработка комбинаций
      const promises = limitedCombinations.map((combination) =>
        limit(() => fetchDataForCombination(combination))
      );

      // Выполняем задачи
      await Promise.all(promises);
      setPopularResults([]); // Очистка состояния перед началом новой обработки
      // После завершения всех запросов обновляем состояние одним вызовом
      setPopularResults(results);
      setTimeout(1000);
      console.log("Итоговые результаты popular:", results);
      console.log("Итоговые результаты popular:", budgetResults);
    } catch (error) {
      console.error(`Ошибка при обработке комбинаций:`, error);
    }
  };

  useEffect(() => {
    if (!budgetResults || budgetResults.length === 0) {
      console.warn("budgetResults пуст или не определён.");
      setCheapestBudget(null);
      return;
    }

    let cheapest = null;
    let lowestCost = Infinity;

    budgetResults.forEach((item) => {
      const totalCost = item.result?.optimalData?.totalCost;

      if (totalCost !== undefined && totalCost < lowestCost) {
        lowestCost = totalCost;
        cheapest = item;
      }
    });

    setCheapestBudget(cheapest);
  }, [budgetResults]); // зависимость от budgetResults

  useEffect(() => {
    if (!popularResults || popularResults.length === 0) {
      console.warn("popularResults пуст или не определён.");
      setCheapestPopular(null);
      return;
    }

    let cheapest = null;
    let lowestCost = Infinity;

    popularResults.forEach((item) => {
      const totalCost = item.result?.optimalData?.totalCost;

      if (totalCost !== undefined && totalCost < lowestCost) {
        lowestCost = totalCost;
        cheapest = item;
      }
    });

    setCheapestPopular(cheapest);
  }, [popularResults]); // зависимость от budgetResults

  const handleExecuteAndFindBudget = async () => {
    try {
      if (Object.keys(analoguesForEach).length > 0) {
        const { budgetsCombinations } = generateCombinations(analoguesForEach);

        console.log("Все возможные комбинации budgets:", budgetsCombinations);
        // Выполняем обработку бюджетных комбинаций
        await executeForBudgetCombinations(budgetsCombinations);
        // После успешного выполнения ищем самый бюджетный вариант
      } else {
        console.warn("analoguesForEach пуст.");
      }
    } catch (error) {
      console.error("Ошибка при выполнении операций:", error);
    }
  };

  const handleExecuteAndFindPopular = async () => {
    try {
      if (Object.keys(analoguesForEach).length > 0) {
        const { i, popularsCombinations } =
          generateCombinations(analoguesForEach);

        console.log("Все возможные комбинации populars:", popularsCombinations);
        // Выполняем обработку бюджетных комбинаций
        await executeForPopularCombinations(popularsCombinations);
        // После успешного выполнения ищем самый бюджетный вариант
      } else {
        console.warn("analoguesForEach пуст.");
      }
    } catch (error) {
      console.error("Ошибка при выполнении операций:", error);
    }
  };

  // Реагируем на изменения cheapestBudget
  useEffect(() => {
    if (cheapestBudget) {
      console.log("Обновленный самый бюджетный вариант:", cheapestBudget);
    }
  }, [cheapestBudget]); // Зависимость от cheapestBudget

  // Реагируем на изменения cheapestBudget
  useEffect(() => {
    if (cheapestPopular) {
      console.log(
        "Обновленный самый бюджетный популярный вариант:",
        cheapestPopular
      );
    }
  }, [cheapestPopular]); // Зависимость от cheapestBudget

  useEffect(() => {
    const fetchData = async () => {
      try {
        let prices = [];
        let method = null; // Переменная для хранения cheapestMethod

        if (selectedProduction[0]?.length > 4) {
          const productSources = selectedProduction[0][4];

          const pricePromises = namesAndQuantities.map(async (item) => {
            const productionSourceObj = productSources[item[0]];

            if (productionSourceObj && productionSourceObj.production) {
              let result = await getListPricesFunction(
                [item[0]],
                paymentMethod,
                productionSourceObj.production
              );

              if (result && result.prices) {
                return {
                  price: parseInt(result.prices[0].replace(/\s+/g, ""), 10),
                  cheapestMethod: result.cheapestMethod || null,
                };
              } else {
                console.error(
                  `Неверный формат ответа для продукта: ${item[0]}`
                );
                return { price: 0, cheapestMethod: null };
              }
            } else {
              console.error(
                `Production source not found for product: ${item[0]}`
              );
              return { price: 0, cheapestMethod: null };
            }
          });

          const results = await Promise.all(pricePromises);

          prices = results.map((res) => res.price);
          method =
            results.find((res) => res.cheapestMethod)?.cheapestMethod || null;
        } else {
          let namesOfProduct = namesAndQuantities.map((item) => item[0]);
          let nameProduction = selectedProductionName[0];
          if (!nameProduction) return;

          let result = await getListPricesFunction(
            namesOfProduct,
            paymentMethod,
            nameProduction
          );
          console.log(result);
          if (result && result.prices) {
            prices = result.prices.map((price) =>
              parseInt(price.replace(/\s+/g, ""), 10)
            );

            method = result.cheapestMethod ? result.cheapestMethod : null;
          } else {
            console.error("Неверный формат ответа от listPrices");
          }
        }

        // Обновляем состояние cheapestMethod только для "Оптимального способа"
        if (selectedDeliveryOption === "Оптимальный способ" && method) {
          setCheapestMethod(method);
        } else {
          setCheapestMethod(null);
        }

        // Обновляем состояние данных для "Оптимального маршрута"
        const updatedProductCosts = prices.map((price) =>
          isNaN(price) ? 0 : price
        );

        const additionalProductCosts = selectedProduction.map((item) => {
          const parsedCost =
            typeof item[1] === "string"
              ? parseInt(item[1].replace(/\s+/g, ""), 10)
              : item[1];
          return isNaN(parsedCost) ? 0 : parsedCost;
        });

        const combinedProductCosts = [
          ...updatedProductCosts,
          ...additionalProductCosts,
        ];

        setProductCosts(combinedProductCosts);

        const initialPricesWithMarkup = combinedProductCosts.map((cost) => {
          return Math.round(cost);
        });

        setItemPrices(initialPricesWithMarkup);

        const total = initialPricesWithMarkup.reduce((acc, price, index) => {
          const quantity =
            index < namesAndQuantities.length
              ? namesAndQuantities[index]?.[1] || 1
              : selectedProduction[index - namesAndQuantities.length]?.[2] || 1;
          return acc + price * quantity;
        }, 0);

        setTotalPrice(total);

        const totaly = combinedProductCosts.reduce((acc, cost, index) => {
          const quantity =
            index < namesAndQuantities.length
              ? namesAndQuantities[index][1]
              : selectedProduction[index - namesAndQuantities.length][2];
          return acc + cost * quantity;
        }, 0);

        setTotalCost(totaly);

        updateCombinedData(initialPricesWithMarkup);
      } catch (error) {
        console.error("Failed to fetch prices:", error);
      }
    };

    fetchData();
  }, [
    namesAndQuantities,
    selectedProduction,
    selectedProductionName,
    paymentMethod,
    updateCombinedData,
    selectedDeliveryOption,
    selectedOption,
    getListPricesFunction,
  ]);

  useEffect(() => {
    if (cheapestBudget?.combination && cheapestBudget.combination.length > 0) {
      const fetchPrices = async () => {
        const prices = {};
        try {
          // Проходим по каждому элементу combination
          for (const [index, item] of cheapestBudget.combination.entries()) {
            const productName = item[0]; // Название товара
            const productionName =
              cheapestBudget.result.optimalData.productSources[productName]
                .production; // Производство по ключу товара

            // Вызываем listPrices для текущего товара
            const result = await listPrices(
              [productName],
              paymentMethod,
              productionName
            );
            prices[index] = result.prices[0] || "N/A"; // Сохраняем цену
          }
        } catch (error) {
          console.error("Ошибка при расчёте цен:", error);
          // Если ошибка, заполняем "Ошибка" для всех товаров
          cheapestBudget.combination.forEach((_, index) => {
            prices[index] = "Ошибка";
          });
        }

        setCalculatedPrices(prices); // Устанавливаем рассчитанные цены
      };

      fetchPrices();
    }
  }, [cheapestBudget, paymentMethod]);

  useEffect(() => {
    if (
      cheapestPopular?.combination &&
      cheapestPopular.combination.length > 0
    ) {
      const fetchPrices = async () => {
        const prices = {};
        try {
          // Проходим по каждому элементу combination
          for (const [index, item] of cheapestPopular.combination.entries()) {
            const productName = item[0]; // Название товара
            const productionName =
              cheapestPopular.result.optimalData.productSources[productName]
                .production; // Производство по ключу товара

            // Вызываем listPrices для текущего товара
            const result = await listPrices(
              [productName],
              paymentMethod,
              productionName
            );
            prices[index] = result.prices[0] || "N/A"; // Сохраняем цену
          }
        } catch (error) {
          console.error("Ошибка при расчёте цен:", error);
          // Если ошибка, заполняем "Ошибка" для всех товаров
          cheapestPopular.combination.forEach((_, index) => {
            prices[index] = "Ошибка";
          });
        }

        setCalculatedPricesPopular(prices); // Устанавливаем рассчитанные цены
      };

      fetchPrices();
    }
  }, [cheapestPopular, paymentMethod]);

  useEffect(() => {
    // Если selectedOption не "Общестрой", по умолчанию ставим процент накрутки 10%
    if (selectedOption !== "Общестрой" && discountPercentage === -1) {
      setDiscountPercentage(10); // Устанавливаем 10% по умолчанию для накрутки
    }
  }, [selectedOption, discountPercentage]);

  useEffect(() => {
    const updatedPrices = productCosts.map((cost) => {
      return selectedOption === "Общестрой"
        ? Math.round(cost * (1.0 - discountPercentage / 100)) // Скидка
        : Math.round(cost * (1.0 + discountPercentage / 100)); // Накрутка
    });

    setItemPrices(updatedPrices);

    const total = updatedPrices.reduce((acc, price, index) => {
      const quantity =
        index < namesAndQuantities.length
          ? namesAndQuantities[index]?.[1] || 1
          : selectedProduction[index - namesAndQuantities.length]?.[2] || 1;
      return acc + price * quantity;
    }, 0);

    setTotalPrice(total);

    updateCombinedData(updatedPrices);
  }, [
    discountPercentage,
    productCosts,
    namesAndQuantities,
    selectedProduction,
    updateCombinedData,
    selectedOption,
  ]);

  const handlePercentageChange = (e) => {
    const value = e.target.value;

    // Если поле пустое, устанавливаем значение 0
    if (value === "") {
      setDiscountPercentage(0);
    } else {
      const newDiscountPercentage = parseFloat(value);

      // Устанавливаем значение, если это корректное число
      if (!isNaN(newDiscountPercentage)) {
        setDiscountPercentage(newDiscountPercentage);
      }
    }
  };

  const handleFillProductsModal = () => {
    setIsModalBitrixVisible(true);

    const dataToSave = namesAndQuantities.map((item, index) => [
      item[0],
      item[1],
      itemPrices[index],
    ]);

    if (!isSelfPickup) {
      selectedProduction.forEach((item, index) => {
        dataToSave.push([
          cheapestMethod || selectedDeliveryOption, // Используем cheapestMethod, если есть
          item[2],
          itemPrices[index + namesAndQuantities.length],
        ]);
      });
    }
    console.log("dataToSave", dataToSave);
    onSaveToPDF(dataToSave);
  };

  const handleFillProductsModalAdditional = (
    names,
    quantities,
    price,
    delivery,
    deliveryTrip,
    priceDelivery
  ) => {
    setIsModalBitrixVisible(true);
    // Собираем данные из names, quantities и units
    const dataToSave = names.map((name, index) => [
      name, // Название продукта
      quantities[index] || 0, // Количество, если доступно
      parseInt(price[index]) || 0, // Цена из itemPrices
    ]);
    console.log("data to save", dataToSave);
    if (!isSelfPickup) {
      selectedProduction.forEach((item, index) => {
        dataToSave.push([
          delivery || selectedDeliveryOption, // Используем cheapestMethod, если есть
          parseInt(deliveryTrip) || 0,
          parseInt(priceDelivery) || 0,
        ]);
      });
    }

    onSaveToPDF(dataToSave);
  };

  const handlePriceChange = (index, e) => {
    const newPrices = [...itemPrices];
    const newPrice = parseFloat(e.target.value);
    if (!isNaN(newPrice)) {
      newPrices[index] = newPrice;
      setItemPrices(newPrices);

      const total = newPrices.reduce((acc, price, index) => {
        const quantity =
          index < namesAndQuantities.length
            ? namesAndQuantities[index]?.[1] || 1
            : selectedProduction[index - namesAndQuantities.length]?.[2] || 1;
        return acc + price * quantity;
      }, 0);

      setTotalPrice(total);

      updateCombinedData(newPrices);
    }
  };
  const handlePriceChangeReis = (index, e) => {
    const newPrices = [...itemPrices];
    const newPricePerTrip = parseFloat(e.target.value); // Новая цена за один рейс
    const reisIndex = index - namesAndQuantities.length; // Индекс рейса в массиве selectedProduction
    const quantity = selectedProduction[reisIndex]?.[2] || 1; // Количество рейсов

    if (!isNaN(newPricePerTrip)) {
      // Обновляем цену за один рейс в массиве цен
      newPrices[index] = newPricePerTrip * quantity; // Общая цена за рейсы = цена за один рейс * количество рейсов
      setItemPrices(newPrices);

      // Пересчитываем общую сумму всех товаров и рейсов
      setTotalPrice(calculateTotalPrice());

      updateCombinedData(newPrices);
    }
  };

  const formatNumber = (number) => {
    return new Intl.NumberFormat("ru-RU").format(number);
  };
  useEffect(() => {
    // Вызываем calculateTotalPrice только при изменении зависимостей
    const calculateTotalPrice = () => {
      const total = namesAndQuantities.reduce((acc, item, index) => {
        const itemTotal = itemPrices[index] * (item[1] || 1); // Цена * Количество для товаров
        return acc + itemTotal;
      }, 0);

      const reisTotal = selectedProduction.reduce((acc, item, index) => {
        const reisIndex = index + namesAndQuantities.length;
        const reisTotal = itemPrices[reisIndex]; // Цена рейса
        return acc + reisTotal;
      }, 0);

      return total + reisTotal; // Общая сумма товаров и рейсов
    };

    setTotalPrice(calculateTotalPrice());
  }, [namesAndQuantities, itemPrices, selectedProduction]); // Зависимости

  const calculateTotalPrice = () => {
    const total = namesAndQuantities.reduce((acc, item, index) => {
      const itemTotal = itemPrices[index] * (item[1] || 1); // Цена * Количество для товаров
      return acc + itemTotal;
    }, 0);

    const reisTotal = selectedProduction.reduce((acc, item, index) => {
      const reisIndex = index + namesAndQuantities.length;
      const reisTotal = itemPrices[reisIndex]; // Цена * Количество для рейсов
      return acc + reisTotal;
    }, 0);
    return total + reisTotal; // Общая сумма товаров и рейсов
  };

  const formatPrice = (price) => {
    if (typeof price !== "undefined") {
      return Math.round(price)
        .toString()
        .replace(/\B(?=(\d{3})+(?!\d))/g, " ");
    }
    return "";
  };

  const marginPercentage = (sellingPrice, costPrice) => {
    if (costPrice === 0) return "0%"; // Возвращаем "0%" если нет наценки
    return selectedOption === "Общестрой"
      ? `${(((sellingPrice - costPrice) / costPrice) * 100).toFixed(0)}%` // Логика скидки
      : `${(((sellingPrice - costPrice) / costPrice) * 100).toFixed(0)}%`; // Логика накрутки
  };

  // Функция для получения текста подсказки
  const generateTooltipText = async (name, quantity) => {
    try {
      const tooltipData = await fetchTooltip(name, quantity);

      if (tooltipData.length > 0) {
        const { density, volumeRequired } = tooltipData[0];
        let text = ``;
        if (density) text += `Плотность: ${density}`;
        if (volumeRequired)
          text += `\nПокрываемая площадь: ${volumeRequired} м²`;
        if (text === "") text = "-";
        return text;
      } else {
        return `Товар ${name} не найден.`;
      }
    } catch (error) {
      console.error("Ошибка при получении данных:", error);
      return `Ошибка при получении данных для товара ${name}`;
    }
  };

  // Асинхронно обновляем текст подсказки
  useEffect(() => {
    const fetchAllTooltips = async () => {
      const newTooltipTexts = {};

      // Асинхронно получаем текст для каждого элемента
      for (const [index, item] of namesAndQuantities.entries()) {
        const text = await generateTooltipText(item[0], item[1]);
        newTooltipTexts[index] = text; // Сохраняем текст в состоянии
      }

      setTooltipTexts(newTooltipTexts);
    };

    fetchAllTooltips();
  }, [namesAndQuantities]);

  //*******************popular******************************************* */
  const [inputValuesPopular, setInputValuesPopular] = React.useState(() => {
    return (
      cheapestPopular?.combination.map((_, index) => {
        return calculatedPricesPopular[index]
          ? calculatedPricesPopular[index].replace(/\s/g, "") // Удаляем пробелы для корректного числового формата
          : ""; // Если данных нет, задаём пустую строку
      }) || []
    );
  });

  React.useEffect(() => {
    setInputValuesPopular(() => {
      return (
        cheapestPopular?.combination.map((_, index) => {
          return calculatedPricesPopular[index]
            ? calculatedPricesPopular[index].replace(/\s/g, "") // Удаляем пробелы
            : ""; // Если данных нет, задаём пустую строку
        }) || []
      );
    });
  }, [calculatedPricesPopular, cheapestPopular]);

  const handleInputChangePopular = (idx, value) => {
    setInputValuesPopular((prev) => {
      const updated = [...prev];
      updated[idx] = value.replace(/[^\d]/g, ""); // Только цифры
      return updated;
    });
  };

  const [deliveryInputPopular, setDeliveryInputPopular] = React.useState(
    cheapestPopular?.result?.optimalData?.deliveryCostPerTrip || "0"
  );

  React.useEffect(() => {
    setDeliveryInputPopular(
      cheapestPopular?.result?.optimalData?.deliveryCostPerTrip || "0"
    );
  }, [cheapestPopular?.result?.optimalData?.deliveryCostPerTrip]);

  //******************************************************************************** */

  const [inputValues, setInputValues] = React.useState(() => {
    return (
      cheapestBudget?.combination.map((_, index) => {
        return calculatedPrices[index]
          ? calculatedPrices[index].replace(/\s/g, "") // Удаляем пробелы для корректного числового формата
          : ""; // Если данных нет, задаём пустую строку
      }) || []
    );
  });

  React.useEffect(() => {
    setInputValues(() => {
      return (
        cheapestBudget?.combination.map((_, index) => {
          return calculatedPrices[index]
            ? calculatedPrices[index].replace(/\s/g, "") // Удаляем пробелы
            : ""; // Если данных нет, задаём пустую строку
        }) || []
      );
    });
  }, [calculatedPrices, cheapestBudget]);

  const handleInputChange = (idx, value) => {
    setInputValues((prev) => {
      const updated = [...prev];
      updated[idx] = value.replace(/[^\d]/g, ""); // Только цифры
      return updated;
    });
  };

  const [deliveryInput, setDeliveryInput] = React.useState(
    cheapestBudget?.result?.optimalData?.deliveryCostPerTrip || "0"
  );

  React.useEffect(() => {
    setDeliveryInput(
      cheapestBudget?.result?.optimalData?.deliveryCostPerTrip || "0"
    );
  }, [cheapestBudget?.result?.optimalData?.deliveryCostPerTrip]);

  const calculateColumnTotal = () => {
    // Сумма по товарам
    const itemsTotal = cheapestPopular?.combination?.reduce(
      (sum, item, index) => {
        const inputValue = inputValuesPopular[index]
          ? parseInt(inputValuesPopular[index].replace(/\s/g, ""), 10) // Удаляем пробелы
          : 0; // Значение из input
        const rowTotal = inputValue * (item[1] || 0); // Умножение input на количество
        return sum + rowTotal; // Добавляем к общей сумме
      },
      0
    );

    // Стоимость доставки
    const deliveryTotal = !isSelfPickup
      ? parseInt(deliveryInputPopular || 0, 10) *
        (cheapestPopular?.result?.optimalData?.numOfTrips || 0)
      : 0; // Если не самовывоз, учитываем доставку

    return itemsTotal + deliveryTotal; // Общая сумма: товары + доставка
  };

  const calculateColumnTotalBudget = () => {
    // Сумма по товарам
    const itemsTotal = cheapestBudget?.combination?.reduce(
      (sum, item, index) => {
        const inputValue = inputValues[index]
          ? parseInt(inputValues[index].replace(/\s/g, ""), 10) // Удаляем пробелы
          : 0; // Значение из input
        const rowTotal = inputValue * (item[1] || 0); // Умножение input на количество
        return sum + rowTotal; // Добавляем к общей сумме
      },
      0
    );

    // Стоимость доставки
    const deliveryTotal = !isSelfPickup
      ? parseInt(deliveryInput || 0, 10) *
        (cheapestBudget?.result?.optimalData?.numOfTrips || 0)
      : 0; // Если не самовывоз, учитываем доставку

    return itemsTotal + deliveryTotal; // Общая сумма: товары + доставка
  };

  return (
    <Section id="commercial-offer" $isVisible={isVisible}>
      <div className="header-container">
        <h2>Коммерческое предложение</h2>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <h3 style={{ fontWeight: "normal" }}>
            Выбрано производство: {selectedProductionName}
          </h3>
          <h3 style={{ fontWeight: "normal" }}>
            Дата обновления цен:{" "}
            {selectedProduction[0] && selectedProduction[0][3]
              ? selectedProduction[0][3]
              : "Дата не указана"}
          </h3>
        </div>
      </div>
      <div>
        <label>
          {selectedOption === "Общестрой"
            ? "Введите процент скидки"
            : "Введите процент накрутки"}
        </label>
        <input
          type="number"
          id="discount-percentage"
          min="0"
          placeholder=""
          value={discountPercentage || ""} // Если значение null или undefined, отображается пустая строка
          onChange={handlePercentageChange}
          style={{
            width: "90px",
            padding: "0.5rem",
            border: "1px solid #ccc",
            borderRadius: "3px",
            fontSize: "0.875rem",
            boxSizing: "border-box",
          }}
        />
        <label>%</label>
      </div>
      <p style={{ color: "red", fontWeight: "bold" }}>Указаны продажные цены</p>
      <Table widths={[2, 30, 5, 5, 10, 10, 10, 5]}>
        <thead id="kp-table-head">
          <tr>
            <th>№</th>
            <th>Наименование товара</th>
            <th>Количество</th>
            <th>Ед.измерения</th>
            <th>Цена (руб.)</th>
            <th>Стоимость (руб.)</th>
            <th>
              {selectedOption === "Общестрой"
                ? "Цена продажная (руб.)"
                : "Себестоимость (руб.)"}
            </th>
            <th>{selectedOption === "Общестрой" ? "Скидка" : "Маржа"}</th>
          </tr>
        </thead>
        <tbody id="kp-table-body">
          {namesAndQuantities.map((item, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                <TooltipWrapper text={tooltipTexts[index] || "Загрузка..."}>
                  {item[0]}
                </TooltipWrapper>
              </td>
              <td>{item[1]}</td>
              <td>{item[2]}</td>
              <td>
                <input
                  type="number"
                  className="price-textbox"
                  min="0"
                  step="100"
                  value={
                    itemPrices[index] !== undefined ? itemPrices[index] : ""
                  } // Убедитесь, что значение не undefined
                  onChange={(e) => handlePriceChange(index, e)}
                  style={{
                    padding: "0.5rem",
                    fontSize: "1rem",
                    width: "100%",
                    boxSizing: "border-box",
                  }}
                />
              </td>
              <td>{formatPrice(itemPrices[index] * item[1])}</td>
              <td>{formatPrice(productCosts[index])}</td>
              <td>
                {marginPercentage(itemPrices[index], productCosts[index])}
              </td>
            </tr>
          ))}
          {!isSelfPickup &&
            selectedProduction.map((item, index) => (
              <tr key={index + namesAndQuantities.length}>
                <td>{index + 1 + namesAndQuantities.length}</td>
                {/* Заменяем на cheapestMethod если выбран "Оптимальный маршрут" */}
                <td>
                  {selectedDeliveryOption === "Оптимальный способ" &&
                  cheapestMethod
                    ? cheapestMethod
                    : selectedDeliveryOption}
                </td>
                <td>{item[2].toString()}</td>
                <td>рейс</td>
                <td>
                  {/* Цена за один рейс */}
                  <input
                    type="number"
                    className="price-textbox"
                    min="0"
                    step="100"
                    value={
                      itemPrices[index + namesAndQuantities.length] / item[2] ||
                      0
                    } // Делим общую стоимость на количество рейсов для получения цены за один рейс
                    onChange={(e) =>
                      handlePriceChangeReis(
                        index + namesAndQuantities.length,
                        e
                      )
                    }
                    style={{
                      padding: "0.5rem",
                      fontSize: "1rem",
                      width: "100%",
                      boxSizing: "border-box",
                    }}
                  />
                </td>
                <td>
                  {/* Стоимость = цена за один рейс * количество рейсов */}
                  {formatPrice(
                    (itemPrices[index + namesAndQuantities.length] / item[2]) *
                      item[2]
                  )}
                </td>
                <td>
                  {formatPrice(
                    productCosts[index + namesAndQuantities.length] / item[2]
                  )}
                </td>
                <td>
                  {marginPercentage(
                    itemPrices[index + namesAndQuantities.length] / item[2], // Используем цену за один рейс
                    productCosts[index + namesAndQuantities.length] / item[2] // Стоимость одного рейса
                  )}
                </td>
              </tr>
            ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="5">
              <BlueButton onClick={handleFillProductsModal}>
                <i className="material-icons">assignment</i>
                Заполнить товары в сделке Битрикс
              </BlueButton>
            </td>
            <td id="cost-total" colSpan="2">
              Итого: {formatPrice(calculateTotalPrice())} руб. <br />
              <br />
              {selectedOption !== "Общестрой" && (
                <StyledText
                  value={((totalPrice - totalCost) / (totalCost / 100)).toFixed(
                    0
                  )}
                  displayValue={`Маржа: ${formatPrice(
                    totalPrice - totalCost
                  )} руб.`}
                />
              )}
            </td>
            <td>
              {selectedOption !== "Общестрой" ? (
                <StyledText
                  value={((totalPrice - totalCost) / (totalCost / 100)).toFixed(
                    0
                  )}
                  displayValue={(
                    (totalPrice - totalCost) /
                    (totalCost / 100)
                  ).toFixed(0)}
                  unit="%"
                />
              ) : (
                ``
              )}
            </td>
          </tr>
        </tfoot>
      </Table>
      {/* Дополнительные таблицы для "Общестрой" */}

      {selectedOption === "Общестрой" && (
        <>
          <div>
            {namesAndQuantities?.length === 1 ? (
              // Проверяем, что товаров ровно один
              <TextLabel
                labelKey={"startOne"}
                clientName={clientName}
                variables={{
                  requestedProduct:
                    namesAndQuantities[0]?.[0] || "Неизвестный товар",
                  pricePerPack: itemPrices[0] || 0, // стоимость за упаковку
                  totalCost: formatPrice(calculateTotalPrice() || 0), // общая стоимость за материал
                }}
              />
            ) : namesAndQuantities?.length > 1 ? (
              <TextLabel
                labelKey={"startMany"}
                clientName={clientName}
                items={namesAndQuantities.map((item, index) => ({
                  name: item?.[0] || "Неизвестный товар",
                  pricePerPack: formatPrice(itemPrices[index] || 0), // Используем index для получения цены
                  totalCost: formatPrice(
                    (item?.[1] || 0) * (itemPrices[index] || 0)
                  ), // Общая стоимость
                }))}
              />
            ) : (
              <p>Список товаров пуст или данные недоступны</p>
            )}
          </div>
          <div style={{ marginTop: "1.25rem" }}>
            <ToggleVisibility
              labelkey="startOne"
              buttonComponent={(props) => (
                <Button variant="outlined" color="success" {...props}>
                  Нет (не бронируем/оформляем)
                </Button>
              )}
            >
              <TextLabel labelKey={"priceOne"} clientName={clientName} />
            </ToggleVisibility>
          </div>
          <div style={{ marginTop: "1.25rem" }}>
            <Button
              variant="outlined"
              color="success"
              onClick={() => {
                toggleTable1Visibility(); // Переключение видимости таблицы
                //handleExecuteAndFindPopular();
              }}
            >
              {isTable1Visible
                ? "Скрыть таблицу"
                : `Да, есть цена дешевле (общая стоимость меньше на ${formatPrice(
                    calculateTotalPrice() - calculateColumnTotal()
                  )} руб.)`}
            </Button>

            {isTable1Visible && (
              <>
                <Table widths={[2, 30, 5, 5, 10, 10, 10]}>
                  <thead>
                    <tr>
                      <th>№</th>
                      <th>Наименование товара</th>
                      <th>Количество</th>
                      <th>Ед.измерения</th>
                      <th>Цена (руб.)</th>
                      <th>Стоимость (руб.)</th>
                      <th>
                        {selectedOption === "Общестрой"
                          ? "Цена продажная (руб.)"
                          : "Себестоимость (руб.)"}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {cheapestPopular?.combination &&
                    cheapestPopular.combination.length > 0 ? (
                      <>
                        {/* Основные строки с данными */}
                        {cheapestPopular.combination.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td> {/* Порядковый номер */}
                            <TooltipWrapper
                              text={tooltipTexts[index] || "Загрузка..."}
                            >
                              <td style={{ paddingLeft: "10px" }}>
                                {item[0] || "N/A"}
                              </td>
                            </TooltipWrapper>
                            <td>{item[1] || "N/A"}</td> {/* Ед. измерения */}
                            <td>{item[2] || "N/A"}</td> {/* Цена */}
                            <td>
                              <input
                                type="number"
                                min="0"
                                step="100"
                                value={
                                  inputValuesPopular[index] !== undefined
                                    ? inputValuesPopular[index]
                                    : ""
                                } // Убедитесь, что значение не undefined
                                onChange={(e) =>
                                  handleInputChangePopular(
                                    index,
                                    e.target.value
                                  )
                                } // Передаём только значение
                                style={{
                                  padding: "0.5rem",
                                  fontSize: "1rem",
                                  width: "100%",
                                  boxSizing: "border-box",
                                }}
                              />
                            </td>
                            <td>
                              {inputValuesPopular[index] && item[1]
                                ? (
                                    parseInt(inputValuesPopular[index], 10) *
                                    item[1]
                                  ).toLocaleString("ru-RU") // Умножение и форматирование результата
                                : "N/A"}
                            </td>
                            <td>{calculatedPricesPopular[index] || "N/A"}</td>
                          </tr>
                        ))}
                        {!isSelfPickup && (
                          <tr>
                            <td>{cheapestPopular.combination.length + 1}</td>{" "}
                            {/* Номер строки */}
                            <td>
                              {cheapestPopular.result.optimalDeliveryMethod ||
                                "Не указан"}
                            </td>{" "}
                            {/* Способ доставки */}
                            <td>
                              {cheapestPopular.result.optimalData.numOfTrips ||
                                "N/A"}
                            </td>{" "}
                            {/* Количество рейсов */}
                            <td>рейс</td> {/* Единица измерения */}
                            <td>
                              <input
                                type="number"
                                min="0"
                                step="100"
                                value={deliveryInputPopular} // Значение из состояния
                                onChange={
                                  (e) =>
                                    setDeliveryInputPopular(
                                      e.target.value.replace(/[^\d]/g, "")
                                    ) // Только цифры
                                }
                                style={{
                                  padding: "0.5rem",
                                  fontSize: "1rem",
                                  width: "100%",
                                  boxSizing: "border-box",
                                }}
                              />
                            </td>
                            <td>
                              {formatNumber(
                                parseInt(deliveryInputPopular || 0, 10) * // Используем значение из поля ввода
                                  (cheapestPopular.result.optimalData
                                    .numOfTrips || 0)
                              )}
                            </td>
                            {/* Общая стоимость доставки */}
                            <td>
                              {formatNumber(
                                cheapestPopular.result.optimalData
                                  .deliveryCostPerTrip || 0
                              )}
                            </td>{" "}
                            {/* Стоимость за рейс */}
                          </tr>
                        )}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={8}>Нет данных</td>
                      </tr>
                    )}
                  </tbody>

                  <tfoot>
                    <tr>
                      <td colSpan="5">
                        <PurpleButton
                          onClick={() =>
                            handleFillProductsModalAdditional(
                              cheapestPopular.combination.map(
                                (item) => item[0]
                              ), // names
                              cheapestPopular.combination.map(
                                (item) => item[1]
                              ), // quantities
                              inputValuesPopular, // Количество берём из полей ввода
                              cheapestPopular.result.optimalDeliveryMethod,
                              cheapestPopular.result.optimalData.numOfTrips,
                              deliveryInputPopular
                            )
                          }
                        >
                          <i className="material-icons">assignment</i>
                          Заполнить данные товары в сделке Битрикс
                        </PurpleButton>
                      </td>
                      <td id="cost-total" colSpan="2">
                        Итого: {formatNumber(calculateColumnTotal())} руб.
                      </td>{" "}
                      {/* Общая сумма */}
                    </tr>
                  </tfoot>
                </Table>
                {namesAndQuantities.length === 1 &&
                  cheapestPopular?.combination?.[0]?.[0] && (
                    <TextLabel
                      labelKey={"popularOne"}
                      clientName={clientName}
                      variables={{
                        popularAlternative: cheapestPopular.combination[0][0],
                        savings: formatPrice(
                          calculateTotalPrice() - calculateColumnTotal()
                        ), // общая стоимость за материал
                      }}
                    />
                  )}
              </>
            )}
          </div>
          <div style={{ marginTop: "1.25rem" }}>
            <Button
              variant="outlined"
              color="success"
              onClick={() => {
                toggleTable2Visibility(); // Переключение видимости таблицы
                //handleExecuteAndFindBudget();
              }}
            >
              {isTable2Visible
                ? "Скрыть таблицу"
                : `Да, есть цена дешевле (общая стоимость меньше на ${formatPrice(
                    calculateTotalPrice() - calculateColumnTotalBudget()
                  )} руб.)`}
            </Button>
            {isTable2Visible && (
              <>
                <Table widths={[2, 30, 5, 5, 10, 10, 10]}>
                  <thead>
                    <tr>
                      <th>№</th>
                      <th>Наименование товара</th>
                      <th>Количество</th>
                      <th>Ед.измерения</th>
                      <th>Цена (руб.)</th>
                      <th>Стоимость (руб.)</th>
                      <th>
                        {selectedOption === "Общестрой"
                          ? "Цена продажная (руб.)"
                          : "Себестоимость (руб.)"}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {cheapestBudget?.combination &&
                    cheapestBudget.combination.length > 0 ? (
                      <>
                        {/* Основные строки с данными */}
                        {cheapestBudget.combination.map((item, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td> {/* Порядковый номер */}
                            <TooltipWrapper
                              text={tooltipTexts[index] || "Загрузка..."}
                            >
                              <td style={{ paddingLeft: "10px" }}>
                                {item[0] || "N/A"}
                              </td>
                            </TooltipWrapper>
                            <td>{item[1] || "N/A"}</td> {/* Ед. измерения */}
                            <td>{item[2] || "N/A"}</td> {/* Цена */}
                            <td>
                              <input
                                type="number"
                                min="0"
                                step="100"
                                value={
                                  inputValues[index] !== undefined
                                    ? inputValues[index]
                                    : ""
                                } // Убедитесь, что значение не undefined
                                onChange={(e) =>
                                  handleInputChange(index, e.target.value)
                                } // Передаём только значение
                                style={{
                                  padding: "0.5rem",
                                  fontSize: "1rem",
                                  width: "100%",
                                  boxSizing: "border-box",
                                }}
                              />
                            </td>
                            <td>
                              {inputValues[index] && item[1]
                                ? (
                                    parseInt(inputValues[index], 10) * item[1]
                                  ).toLocaleString("ru-RU") // Умножение и форматирование результата
                                : "N/A"}
                            </td>
                            <td>{calculatedPrices[index] || "N/A"}</td>
                          </tr>
                        ))}
                        {!isSelfPickup && (
                          <tr>
                            <td>{cheapestBudget.combination.length + 1}</td>{" "}
                            {/* Номер строки */}
                            <td>
                              {cheapestBudget.result.optimalDeliveryMethod ||
                                "Не указан"}
                            </td>{" "}
                            {/* Способ доставки */}
                            <td>
                              {cheapestBudget.result.optimalData.numOfTrips ||
                                "N/A"}
                            </td>{" "}
                            {/* Количество рейсов */}
                            <td>рейс</td> {/* Единица измерения */}
                            <td>
                              <input
                                type="number"
                                min="0"
                                step="100"
                                value={deliveryInput} // Значение из состояния
                                onChange={
                                  (e) =>
                                    setDeliveryInput(
                                      e.target.value.replace(/[^\d]/g, "")
                                    ) // Только цифры
                                }
                                style={{
                                  padding: "0.5rem",
                                  fontSize: "1rem",
                                  width: "100%",
                                  boxSizing: "border-box",
                                }}
                              />
                            </td>
                            <td>
                              {formatNumber(
                                parseInt(deliveryInput || 0, 10) * // Используем значение из поля ввода
                                  (cheapestBudget.result.optimalData
                                    .numOfTrips || 0)
                              )}
                            </td>
                            {/* Общая стоимость доставки */}
                            <td>
                              {formatNumber(
                                cheapestBudget.result.optimalData
                                  .deliveryCostPerTrip || 0
                              )}
                            </td>{" "}
                            {/* Стоимость за рейс */}
                          </tr>
                        )}
                      </>
                    ) : (
                      <tr>
                        <td colSpan={8}>Нет данных</td>
                      </tr>
                    )}
                  </tbody>

                  <tfoot>
                    <tr>
                      <td colSpan="5">
                        <RedButton
                          onClick={() =>
                            handleFillProductsModalAdditional(
                              cheapestBudget.combination.map((item) => item[0]), // names
                              cheapestBudget.combination.map((item) => item[1]), // quantities
                              inputValues, // Количество берём из полей ввода
                              cheapestBudget.result.optimalDeliveryMethod,
                              cheapestBudget.result.optimalData.numOfTrips,
                              deliveryInput
                            )
                          }
                        >
                          <i className="material-icons">assignment</i>
                          Заполнить данные товары в сделке Битрикс
                        </RedButton>
                      </td>
                      <td id="cost-total" colSpan="2">
                        Итого: {formatNumber(calculateColumnTotalBudget())} руб.
                      </td>
                    </tr>
                  </tfoot>
                </Table>
                {namesAndQuantities.length === 1 &&
                  cheapestBudget?.combination?.[0]?.[0] && (
                    // Проверяем, что товаров ровно один
                    <TextLabel
                      labelKey={"budgetOne"}
                      clientName={clientName}
                      variables={{
                        budgetAlternative: cheapestBudget.combination[0][0],
                        savings: formatPrice(
                          calculateTotalPrice() - calculateColumnTotalBudget()
                        ), // общая стоимость за материал
                      }}
                    />
                  )}
              </>
            )}
          </div>
          <div style={{ marginTop: "1.25rem" }}>
            <ToggleVisibility
              buttonComponent={(props) => (
                <Button variant="outlined" color="success" {...props}>
                  Нет, Изовер/Урса хуже
                </Button>
              )}
            >
              <TextLabel labelKey={"additional"} clientName={clientName} />
            </ToggleVisibility>
          </div>
          <div style={{ height: "120px" }}></div>{" "}
          {/* Это создаст 20px пустого пространства */}
        </>
      )}
    </Section>
  );
};

export default CommercialOffer4OS;
