import React, { useState } from "react";
import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

const Choice0OS = ({ onChoiceChange, isDisabled }) => {
  const [selectedOption, setSelectedOption] = useState("Общестрой");

  const handleOptionChange = (event) => {
    const value = event.target.value;
    if (!isDisabled) { // Блокируем изменение, если флаг isDisabled = true
      setSelectedOption(value);
      onChoiceChange(value);
    }
  };

  return (
    <div className="choice0OS" style={{ paddingLeft: '20px' }}> {/* Добавлен отступ слева */}
      <RadioGroup value={selectedOption} onChange={handleOptionChange} row>
        <FormControlLabel
          value="Общестрой"
          control={<Radio />}
          label="Общестрой"
          disabled={isDisabled} // Отключаем изменение при блокировке
          sx={{ fontSize: '1.4rem' }} // Увеличиваем размер шрифта
        />
        <FormControlLabel
          value="ЖБИ"
          control={<Radio />}
          label="ЖБИ"
          disabled={isDisabled} // Отключаем изменение при блокировке
          sx={{ fontSize: '1.4rem' }} // Увеличиваем размер шрифта
        />
        <FormControlLabel
          value="ЖБИ Москва"
          control={<Radio />}
          label="ЖБИ Москва"
          disabled={isDisabled} // Отключаем изменение при блокировке
          sx={{ fontSize: '1.4rem' }} // Увеличиваем размер шрифта
        />
      </RadioGroup>
    </div>
  );
};

export default Choice0OS;
