import React from "react";

const StyledText = ({ value, displayValue, unit }) => {
  // Определяем цвет на основе значения `value`
  const getColor = (value) => {
    if (value < 6) return "var(--RED_BUTTON)"; // Обычный красный из :root
    if (value >= 6 && value < 10) return "var(--YELLOW_COLOR)"; // Обычный жёлтый из :root
    return "var(--GREEN_BUTTON)"; // Обычный зелёный из :root
  };

  return (
    <span style={{ color: getColor(value) }}>
      {displayValue}
      {unit}
    </span>
  );
};

export default StyledText;
