let cachedData = null; // Кэшированные данные, чтобы не запрашивать их повторно
let cachedDestination = null; // Кэшированное место назначения
let cachedDistances = {}; // Кэшированные расстояния для каждого производства
let cachedKadDistance = null; // Кэшированное расстояние до КАД
let cachedCoords = null;
let globalCheapestMethod = null; // Глобальная переменная для хранения результата calculateCheapestDeliveryMethod


const { fetchDistance, fetchDataJBI } = require("./fetches.js"); // Импорт функций для получения данных и расчета расстояний
const defaultCoord = "10.000000, 10.000000"; // Координаты по умолчанию

let cachedCombinations = null; // Кэшированные комбинации

// Фильтруем нежелательные элементы
function filterExcludedProductions(productions) {
  const excludedProductions = new Set([
    "СРЕДНИЕ ЦЕНЫ ОТ ЛОГИСТОВ",
    "Производства",
  ]);
  return productions.filter(
    (production) => !excludedProductions.has(production)
  );
}

// Генерация всех возможных комбинаций с фильтрацией нежелательных элементов
function generateAllCombinations(productions) {
  const result = new Set();
  const maxAdditionalProductions = 1; // для комбинаций от 1 до 4 производств

  function combine(acc, rest, startIndex) {
    const sortedAcc = [...acc].sort();
    result.add(JSON.stringify(sortedAcc));

    if (acc.length === maxAdditionalProductions + 1) return;

    for (let i = startIndex; i < rest.length; i++) {
      acc.push(rest[i]);
      combine(acc, rest, i + 1);
      acc.pop();
    }
  }

  combine([], productions, 0);
  return Array.from(result).map((item) => JSON.parse(item));
}

// Инициализация кэша при запуске
function initializeCombinationCache(productions) {
  if (!cachedCombinations) {
    const filteredProductions = filterExcludedProductions(productions);
    cachedCombinations = generateAllCombinations(filteredProductions);
    //console.log(`Сгенерировано ${cachedCombinations.length} комбинаций`);
  }
}

async function isPointInsideKAD(pointToCheck, kadExit, nearestDistance) {
  const knownInsidePoint = "59.954010, 30.355396";

  // Используем getCachedDistance вместо fetchDistance
  const distanceFromInsidePointToExit = await getCachedDistance(
    knownInsidePoint,
    kadExit
  );
  const distanceFromCheckPointToInsidePoint = await getCachedDistance(
    pointToCheck,
    knownInsidePoint
  );

  // Сравнение расстояний для определения местоположения точки
  if (distanceFromInsidePointToExit > distanceFromCheckPointToInsidePoint) {
    return 0; // Точка внутри КАД
  } else {
    return nearestDistance; // Точка за пределами КАД
  }
}



async function calculateCheapestDeliveryMethod(
  productList,
  destination,
  totalWeight,
  totalVolume
) {
  try {
    if (cachedData === null) {
      cachedData = await fetchDataJBI();
    }

    if (!cachedData || !Array.isArray(cachedData.deliverySheet)) {
      console.error("Ошибка: deliverySheet не загружен или не является массивом.");
      return { cheapestMethod: null, lowestCost: Number.MAX_VALUE };
    }

    const deliverySheet = cachedData.deliverySheet;
    let cheapestMethod = null;
    let lowestCost = Number.MAX_VALUE;

    for (const deliveryMethod of deliverySheet) {
      const method = deliveryMethod[0];
      if (!Array.isArray(deliveryMethod) || deliveryMethod.length < 4) {
        console.error("Ошибка: deliveryMethod не является массивом или имеет недостаточную длину.", deliveryMethod);
        continue;
      }
      const [maxCapacity, minCharge, costPerKm] = deliveryMethod
        .slice(1)
        .map((value) => parseFloat(value.toString().replace(",", ".")));
      //console.log("maxcapacity", maxCapacity)
      const nearestExit = await nearestExitKad(
        cachedData.pointsKadSheet,
        destination
      );
      const nearestDistanceOutsideKAD = await isPointInsideKAD(
        destination,
        nearestExit.coordinates,
        nearestExit.distance
      );

      const deliveryCosts =
        parseInt(minCharge) + costPerKm * nearestDistanceOutsideKAD;
      //console.log("deliverycost ", deliveryCosts)
      const numOfTripsMass = Math.ceil(totalWeight / maxCapacity);
      const numOfTrips = numOfTripsMass;
      const totalCost = deliveryCosts * numOfTrips;
      //console.log("num of trips ", numOfTrips)
      if (totalCost < lowestCost) {
        lowestCost = totalCost;
        cheapestMethod = method;
      }
    }
    globalCheapestMethod = cheapestMethod;

    //console.log(
    //  `Самый дешевый метод доставки: ${cheapestMethod}, Стоимость: ${lowestCost} руб.`
    //);
    return { cheapestMethod, lowestCost };
  } catch (error) {
    console.error("Ошибка при расчете самого дешевого метода доставки:", error);
    throw error;
  }
}

async function calculateTotalCostsWithStops(
  mainProduction,
  additionalProductions,
  productList,
  materialCosts,
  numOfTrips,
  deliveryCostsPerTrip, // передаем массив для каждого производства
  updateDates,
  kadDistance,
  addCosts
) {
  //console.log("calculate: Входные параметры:");
  //console.log("  mainProduction:", mainProduction);
  //console.log("  additionalProductions:", additionalProductions);
  //console.log("  deliveryCostsPerTrip:", deliveryCostsPerTrip);

  let totalMaterialCost = 0;
  const productSources = {};

  // Основное производство
  if (materialCosts[mainProduction]) {
    //console.log(`calculate: Расчет для основного производства: ${mainProduction}`);
    totalMaterialCost += materialCosts[mainProduction].reduce((sum, item) => {
      const product = productList.find(([name]) => name === item.productName);
      if (product) {
        productSources[item.productName] = {
          production: mainProduction,
          updateDate: formatDate(updateDates[mainProduction]),
        };
      }
      const cost = item.price * (product ? product[1] : 0);
      //console.log(`calculate: Материал ${item.productName}, стоимость: ${cost}`);
      return sum + cost;
    }, 0);
  }

  // Дополнительные производства
  additionalProductions.forEach((production) => {
    if (materialCosts[production]) {
      //console.log(`calculate: Расчет для дополнительного производства: ${production}`);
      totalMaterialCost += materialCosts[production].reduce((sum, item) => {
        const product = productList.find(([name]) => name === item.productName);
        if (product && !productSources[item.productName]) {
          productSources[item.productName] = {
            production: production,
            updateDate: formatDate(updateDates[production]),
          };
        }
        const cost = item.price * (product ? product[1] : 0);
        //console.log(`calculate: Материал ${item.productName}, стоимость: ${cost}`);
        return sum + cost;
      }, 0);
    }
  });


  const additionalCost = addCosts * numOfTrips * (additionalProductions.length); 

  // Рассчитываем суммарную стоимость доставки для всех производств
let totalDeliveryCost = 0;

// Проверяем наличие основного производства
const mainProductionDeliveries = deliveryCostsPerTrip.filter(item => item.production === mainProduction);

let targetProduction = mainProduction; // Изначально основное производство

if (mainProductionDeliveries.length === 0) {
    //console.warn(`В массиве deliveryCostsPerTrip не найдено производство ${mainProduction}.`);
    
    if (deliveryCostsPerTrip.length > 0) {
        // Берем первое доступное производство
        targetProduction = deliveryCostsPerTrip[0].production;
        //console.log(`Используем первое доступное производство: ${targetProduction}.`);
    } else {
        console.error('Массив deliveryCostsPerTrip пуст, расчеты невозможны.');
        totalDeliveryCost += additionalCost; // Если требуется добавить только доп. расходы
        //console.log(`Общая стоимость доставки: ${totalDeliveryCost}`);
        return totalDeliveryCost; // Возвращаем итоговую стоимость
    }
}

// Получаем данные для целевого производства
const targetProductionDeliveries = deliveryCostsPerTrip.filter(item => item.production === targetProduction);

// Рассчитываем стоимость доставки
const targetProductionDeliveryCost = targetProductionDeliveries.reduce((sum, item) => sum + item.deliveryCostPerTrip, 0);
//console.log(`Стоимость доставки для производства (${targetProduction}):`, targetProductionDeliveryCost);
totalDeliveryCost += targetProductionDeliveryCost;

// Добавляем дополнительные расходы
totalDeliveryCost += additionalCost;



  //console.log("calculate: Общая стоимость доставки:", totalDeliveryCost);

  const totalCost = totalMaterialCost + totalDeliveryCost + additionalCost;
  //console.log("calculate: Общая итоговая стоимость:", totalCost);

  return {
    mainProduction,
    additionalProductions,
    totalMaterialCost,
    additionalCost,
    totalCost,
    totalDeliveryCost,
    numOfTrips,
    productSources,
  };
}


async function findOptimalProductionRoute(
  allProductions,
  productList,
  materialCosts,
  numOfTrips,
  updateDates,
  deliveryCostsPerTrip,
  kadDistance, 
  addCosts // Получаем массив addCosts
) {
  //console.log("Получен deliveryCostsPerTrip в findOptimalProductionRoute:", numOfTrips);
  //*************************************************************************************************************************** */

  const allRoutes = [];
  // Проверяем, если все товары можно получить с одного производства
  const singleProductionOptions = allProductions.filter(production => 
    productList.every(([productName]) => 
      materialCosts[production] && 
      materialCosts[production].some(item => item.productName === productName)
    )
  );

  // Проверяем стоимость на каждом производстве, если только одно используется
  if (singleProductionOptions.length > 0) {
    const singleProductionCosts = await Promise.all(singleProductionOptions.map(async (production, index) => {
      //console.log(addCosts)
      return await calculateTotalCostsWithStops(
        production,
        [],
        productList,
        materialCosts,
        numOfTrips,
        deliveryCostsPerTrip,
        updateDates,
        kadDistance,
        addCosts // Передаем одно число addCost
      );
    }));

    // Найти производство с минимальной суммой материалов
    const cheapestSingleProductionRoute = singleProductionCosts.reduce((cheapest, current) => 
      current.totalCost < cheapest.totalCost ? current : cheapest
    );
    allRoutes.push(cheapestSingleProductionRoute);
  }

  // Проверяем комбинации производств, если нет подходящих одиночных производств
  if (productList.length > 1 && cachedCombinations) {
    const routePromises = cachedCombinations.map(async (combination, index) => {
      const mainProduction = combination[0];
      const additionalProductions = combination.slice(1);
      // Проверяем, закрываются ли все товары комбинацией производств
      const productsCovered = productList.every(([productName]) => 
        combination.some(production => 
          materialCosts[production] &&
          materialCosts[production].some(item => item.productName === productName)
        )
      );
      if (!productsCovered) return null;

      try {
        //*************************************************************************************************************************** */
        const routeCost = await calculateTotalCostsWithStops(
          mainProduction,
          additionalProductions,
          productList,
          materialCosts,
          numOfTrips,
          deliveryCostsPerTrip,
          updateDates,
          kadDistance,
          addCosts // Передаем одно число addCost
        );
        //console.log("route cost 327 ", routeCost)
        // Фильтрация маршрутов с некорректными данными
        if (routeCost && typeof routeCost === 'object' && routeCost.totalCost !== undefined) {
          return routeCost;
        } else {
          //console.warn("Некорректные данные в routeCost:", routeCost);
          return null;
        }
      } catch (error) {
        console.error("Ошибка при расчете маршрута:", error);
        return null;
      }
    });

    const allRoutesResolved = await Promise.all(routePromises);
    allRoutes.push(...allRoutesResolved.filter(route => route !== null));
  }

  // Фильтруем маршруты с допустимыми значениями стоимости
  let validRoutes = allRoutes.filter(route => route.totalCost < Number.MAX_VALUE);

  // Если не найдено ни одного валидного маршрута, попробуем использовать "СРЕДНИЕ ЦЕНЫ ОТ ЛОГИСТОВ"
  if (validRoutes.length === 0) {
    const logisticProduction = "СРЕДНИЕ ЦЕНЫ ОТ ЛОГИСТОВ";
    const productsFromLogistics = productList.filter(([productName]) =>
      materialCosts[logisticProduction]?.some(item => item.productName === productName)
    );

    const missingProducts = productList.filter(([productName]) =>
      !productsFromLogistics.some(([name]) => name === productName)
    );

    // Если "СРЕДНИЕ ЦЕНЫ ОТ ЛОГИСТОВ" покрывает часть товаров, ищем недостающие
    if (productsFromLogistics.length > 0) {
      
      // Находим комбинации других производств для недостающих товаров
      const additionalRoutes = await Promise.all(cachedCombinations.map(async (combination, index) => {
        const productsCovered = missingProducts.every(([productName]) =>
          combination.some(production =>
            materialCosts[production] &&
            materialCosts[production].some(item => item.productName === productName)
          )
        );

        if (!productsCovered) return null;

        try {
          const routeCost = await calculateTotalCostsWithStops(
            logisticProduction,
            combination,
            productList,
            materialCosts,
            numOfTrips,
            deliveryCostsPerTrip,
            updateDates,
            kadDistance,
            addCosts // Передаем одно число addCost
          );
          //console.log("routeCost",routeCost)
          if (routeCost && typeof routeCost === 'object' && routeCost.totalCost !== undefined) {
            return routeCost;
          } else {
            console.warn("Некорректные данные в routeCost:", routeCost);
            return null;
          }
        } catch (error) {
          console.error("Ошибка при расчете маршрута:", error);
          return null;
        }
      }));

      validRoutes = additionalRoutes.filter(route => route !== null && route.totalCost < Number.MAX_VALUE);
    }
  }

  // Сортируем маршруты по общей стоимости
  validRoutes.sort((a, b) => a.totalCost - b.totalCost);

  // Если есть валидные маршруты, выбираем самый дешевый
  let optimalRoute = validRoutes[0];

  // Обновляем даты обновления цен для оптимального маршрута
  const relevantProductions = optimalRoute?.productSources 
    ? Object.values(optimalRoute.productSources).map(source => source.production)
    : [];

  const relevantUpdateDates = relevantProductions
    .map(production => updateDates[production])
    .filter(date => date && !isNaN(date.getTime()));

  const minUpdateDate = relevantUpdateDates.length > 0 
    ? new Date(Math.min(...relevantUpdateDates))
    : null;

  if (optimalRoute) {
    optimalRoute.updateDates = minUpdateDate 
      ? formatDate(minUpdateDate)
      : formatDate(new Date(1999, 0, 1));
  } else {
    optimalRoute = {
      updateDates: formatDate(new Date(1999, 0, 1))
    };
  }

  //console.log("Оптимальный маршрут найден:", optimalRoute);

  return optimalRoute;
}



async function getProductionDataJBI(
  productList,
  destination,
  paymentMethod,
  deliveryMethod,
  totalWeight,
  totalVolume
) {
  try {
    if (cachedData === null) {
      cachedData = await fetchDataJBI();
    }

     // Если выбран "Оптимальный способ", вычисляем самый дешевый метод доставки
     if (deliveryMethod === "Оптимальный способ") {
      const result = await calculateCheapestDeliveryMethod(
        productList,
        destination,
        totalWeight,
        totalVolume
      );
      deliveryMethod = result.cheapestMethod; // Присваиваем найденный метод доставки
      }


    //calculateCheapestDeliveryMethod(productList, destination, totalWeight, totalVolume);

    const deliverySheet = cachedData.deliverySheet;
    const pointsKadSheet = cachedData.pointsKadSheet;
    const stockSheet = cachedData.stockSheet;
    const productionSheet = cachedData.productionSheet;
    const productNames = productList.map((product) => product[0]);

    const allProductions = productionSheet.map((production) => production[0]);
    initializeCombinationCache(allProductions);




    const suitableProductions = filterProductionsByAllProducts(
      stockSheet,
      productNames
    );
//--------------------------------------------------------
const processProductList = async (productList, stockSheet, paymentMethod, suitableProductions) => {
  let allCombinations = [];  // Массив для хранения всех возможных комбинаций товаров
  console.log("delivery sheet ", deliverySheet)
  // Функция для генерации всех возможных комбинаций для одного производства
  const generateCombinations = async (production, currentCombination, productIndex) => {
    if (productIndex === productList.length) {
      allCombinations.push(currentCombination);  // Добавляем завершенную комбинацию
      return;
    }

    const [productName_2, productQuantity_2] = productList[productIndex];  // Индекс _2 добавлен
    const matchingProducts = stockSheet.filter(
      (row_2) => row_2[1] === production && row_2[2] === productName_2  // Индекс _2 добавлен
    );

    if (matchingProducts.length > 0) {
      await Promise.all(matchingProducts.map(async (productRow_2) => {  // Используем Promise.all для асинхронных операций
        const priceColumn = paymentMethod === "cash" ? 4 : 5;
        const price_2 = parseFloat(productRow_2[priceColumn].replace(/\s/g, ""));  // Цена товара
        const quantity_2 = productQuantity_2;  // Количество товара на складе
        const totalCost_2 = price_2 * quantity_2;  // Общая стоимость товара
        const updateDate_2 = productRow_2[6];  // Дата обновления цены товара (7-й элемент в строке)

        // Преобразуем строку даты в объект Date
        const [day_2, month_2, year_2] = updateDate_2.split('.').map(Number);  // Индекс _2 добавлен
        const dateObj_2 = new Date(year_2, month_2 - 1, day_2);  // Месяцы начинаются с 0

        // Рекурсивно добавляем комбинацию для текущего товара
        await generateCombinations(production, [
          ...currentCombination,
          { 
            production, 
            productName_2, 
            price_2, 
            quantity_2, 
            totalCost_2, 
            updateDate_2: dateObj_2,
            description: productRow_2[11]  // Добавляем описание (столбец L)
          }  // Индекс _2 добавлен
        ], productIndex + 1);
      }));
    }
  };

  // Для каждого подходящего производства, генерируем все возможные комбинации товаров
  await Promise.all(suitableProductions.map(async (production_2) => {  // Асинхронный map
    // Проверяем, есть ли все товары из productList для этого производства
    const hasAllProducts_2 = productList.every(([productName_2]) => {  // Индекс _2 добавлен
      return stockSheet.some((row_2) => row_2[1] === production_2 && row_2[2] === productName_2);  // Индекс _2 добавлен
    });

    if (hasAllProducts_2) {
      await generateCombinations(production_2, [], 0);  // Генерация комбинаций для этого производства
    }
  }));

  // Возвращаем сгенерированные комбинации товаров
  return allCombinations;
};

// Пример использования:
const allCombinations = await processProductList(productList, stockSheet, paymentMethod, suitableProductions, productionSheet, defaultCoord, cachedDistances, getCachedDistance);

// Формируем итоговую таблицу в нужном формате
const resultTable_2 = await Promise.all(allCombinations.map(async (combination_2) => {
  const production_2 = combination_2[0].production;  // Название производства
  const materialSum_2 = combination_2.reduce((sum, item_2) => sum + item_2.totalCost_2, 0);  // Сумма стоимости материалов для всех товаров

  // Находим самую старую дату обновления среди всех товаров в комбинации
  const oldestDate_2 = combination_2.reduce((oldest, item_2) => {
    return item_2.updateDate_2 < oldest ? item_2.updateDate_2 : oldest;
  }, new Date());  // Изначально устанавливаем в текущую дату, чтобы сравнить с ней

  // Форматируем дату для вывода (сначала день, потом месяц)
  const formattedDate_2 = oldestDate_2.toLocaleDateString("ru-RU", {  // Индекс _2 добавлен
    day: "2-digit", 
    month: "2-digit", 
    year: "numeric"
  });

  // Рассчитываем расстояние для каждого производства
  const distances_2 = {};  // Индекс _2 добавлен
  await Promise.all(combination_2.map(async (item_2) => {  // Асинхронный map
    const production_2 = item_2.production;  // Индекс _2 добавлен
    const productionIndex_2 = productionSheet.findIndex((row_2) => row_2[0] === production_2);  // Индекс _2 добавлен
    const coord_2 = productionSheet[productionIndex_2][1] || defaultCoord;  // Индекс _2 добавлен

    // Заменяем пустые строки на 0
    const effectiveDestination = destination === "" ? "0" : destination;

    // Получаем расстояние
    const distance_2 = cachedDistances[coord_2 + "-" + effectiveDestination] || await getCachedDistance(coord_2, effectiveDestination);  // Индекс _2 добавлен
    distances_2[production_2] = distance_2;  // Индекс _2 добавлен
  }));
  const distance_2 = distances_2[production_2];  // Индивидуальное расстояние для текущего производства с индексом _2

  // Расчет стоимости доставки
  let deliveryData2 = 0;
  let totalSum_2 = 0;
  let numOfTrips2 = 0;
  let deliverySum_2 = 0;
  if (destination === "" && deliveryMethod === "") {
  }
  else{
    deliveryData2 = deliverySheet.find(row => row[0] === deliveryMethod);
    const [maxCapacity2, minCharge2, costPerKm2] = deliveryData2.slice(1).map((value) => parseFloat(value.toString().replace(",", ".")));
    // Расчет количества рейсов
    numOfTrips2 = Math.ceil(totalWeight / maxCapacity2);  // Округляем вверх для количества рейсов

    // Стоимость доставки
    deliverySum_2 = minCharge2 + costPerKm2 * distance_2 * numOfTrips2;

    totalSum_2 = materialSum_2 + deliverySum_2;  // Общая сумма (материалы + доставка)
  }

  // Собираем описание для комбинации товаров с порядковыми номерами
  const description_2 = combination_2
  .map((item_2, index) => {
    if (item_2.description) {
      return `${index + 1}) ${item_2.description}`; // Используем оригинальный индекс + 1
    }
    return null; // Пропускаем товары без описания
  })
  .filter(Boolean) // Удаляем null из массива
  .join('<br />'); // Разделяем строки с помощью <br />

  
  // Формируем итоговую строку для таблицы
  return [
    production_2,                                // 1. Производство
    formatNumberWithSpaces(distance_2),           // 2. Расстояние до пункта назначения
    formatNumberWithSpaces(materialSum_2),        // 3. Сумма стоимости материалов (все товары)
    formatNumberWithSpaces(deliverySum_2),        // 4. Стоимость доставки
    formatNumberWithSpaces(numOfTrips2),          // 5. Количество рейсов
    formatNumberWithSpaces(totalSum_2),           // 6. Общая сумма
    formattedDate_2,                              // 7. Дата обновления товара
    description_2                                  // 8. Описание
  ];
}));

// Выводим результат в консоль
console.log("Итоговая таблица:");
console.log(resultTable_2);



//-------------------------------------------------------








    const suitableDelivery = filterNesk(
      stockSheet,
      productNames
    );


    const productionNames = productionSheet.map((production) => production[0]);

    const addressesProductions = suitableProductions.reduce(
      (acc, production) => {
        const productionIndex = productionNames.indexOf(production);
        // Проверка существования индекса и данных
        if (
          productionIndex !== -1 &&
          productionSheet[productionIndex] &&
          productionSheet[productionIndex].length > 2
        ) {
          const address = productionSheet[productionIndex][2];
          acc[production] = address;
        } else {
          console.warn(
            `Не удалось найти адрес для производства: ${production}`
          );
          acc[production] = "Адрес не найден"; // Или другой подходящий дефолтный адрес
        }

        return acc;
      },
      {}
    );

    let materialCosts = suitableProductions.reduce((acc, production, index) => {
      acc[production] = productList.reduce((sum, product) => {
        const [productName, productQuantity] = product;
        const priceColumn = paymentMethod === "cash" ? 4 : 5;
        const price = stockSheet
          .find((row) => row[1] === production && row[2] === productName)
          [priceColumn].replace(/\s/g, "");
        return sum + parseInt(price) * parseInt(productQuantity);
      }, 0);
      return acc;
    }, {});

    const updateDates = allProductions.reduce((acc, production) => {
      const dates = productList
        .map((product) => {
          const productName = product[0];
          const productRow = stockSheet.find(
            (row) => row[1] === production && row[2] === productName
          );
          if (productRow) {
            return new Date(productRow[6].split(".").reverse().join("-"));
          }
          return null; // Возвращаем null, если строки продукта нет
        })
        .filter((date) => date !== null); // Фильтруем null значения

      if (dates.length > 0) {
        acc[production] = new Date(
          Math.min(...dates.map((date) => date.getTime()))
        );
      } else {
        acc[production] = null; // Устанавливаем null, если нет дат
      }
      return acc;
    }, {});

    if (destination === "" && deliveryMethod === "") {
      const resultT = {
        data: suitableProductions.map((production, index) => {
          return [
            production,
            "0",
            formatNumberWithSpaces(materialCosts[production]), // Исправлено для корректного отображения
            "0",
            "0",
            formatNumberWithSpaces(materialCosts[production]), // Исправлено для корректного отображения
            formatDate(updateDates[production]),
          ];
        }),
        kadDistance: 0,
        addressesProductions,
      };

      //console.log("Результат при пустом destination и deliveryMethod:", result);
      let result;

      if (resultT.length === resultTable_2.length) {
        result = resultT;  // Если длина одинаковая, сохраняем resultTable
      } else {
        result = {
          data: resultTable_2,   // Если длина не совпадает, всё равно используем resultTable_2
          kadDistance: 0,        // Аналогично, kadDistance остается 0 или можно использовать resultT.kadDistance
          addressesProductions,  // Используем addressesProductions
        };
      }
      return result;
    }

    let nearestDistance, nearestCoords;

     // Проверка изменения destination
     if (destination !== cachedDestination) {
      ({ distance: nearestDistance, coordinates: nearestCoords } =
        await nearestExitKad(pointsKadSheet, destination));
      cachedKadDistance = nearestDistance;
      cachedCoords = nearestCoords;
      cachedDestination = destination;
    } else {
      nearestDistance = cachedKadDistance;
      nearestCoords = cachedCoords;
    }




    const distances = {};
    await Promise.all(
      suitableDelivery.map(async (production) => {
        const productionIndex = productionNames.indexOf(production);
        if (
          productionIndex !== -1 &&
          productionSheet[productionIndex] &&
          productionSheet[productionIndex].length > 1
        ) {
          const coord = productionSheet[productionIndex][1] || defaultCoord;

          if (cachedDistances[coord + "-" + destination]) {
            distances[production] = cachedDistances[coord + "-" + destination];
          } else {
            const distance = await getCachedDistance(coord, destination); // Используем кэш здесь
            distances[production] = distance;
          }
        } else {
          console.warn(`Не удалось найти координаты для производства: ${production}`);
          distances[production] = Number.MAX_VALUE;
        }
      })
    );
    const deliveryOption = deliverySheet.find((row) => row[0] === deliveryMethod);

    // Проверяем, что deliveryOption найден, иначе предупреждаем и возвращаем
    if (!deliveryOption) {
      console.error(`Метод доставки '${deliveryMethod}' не найден в deliverySheet.`);
      return; // Пропускаем дальнейшую обработку
    }

    const [maxCapacity, minCharge, costPerKm, addCosts] = deliveryOption
      .slice(1)
      .map((value) => parseFloat(value.toString().replace(",", ".")));

    const nearestDistanceOutsideKAD = await isPointInsideKAD(
      destination,
      nearestCoords,
      nearestDistance
    );
    
    
    // Объявляем переменные за пределами функции, чтобы они были доступны
let deliveryCosts = []; // Массив для хранения стоимости доставки для каждого производства
let tripCounts = []; // Массив для хранения количества поездок
let numOfTrips = 0
let deliveryCostsPerTrip = []


deliveryCosts = suitableDelivery.map((production) => {

  const distance = distances[production]; // Индивидуальное расстояние от производства до точки назначения

  const deliveryCostPerTrip = parseInt(minCharge) + costPerKm * distance;

  const numOfTripsMass = Math.ceil(totalWeight / maxCapacity);

  numOfTrips = numOfTripsMass; // Количество поездок для текущего производства

  deliveryCostsPerTrip.push({
    production: production, // Название производства
    deliveryCostPerTrip: deliveryCostPerTrip * numOfTrips, // Общая стоимость для текущего производства
  });

  tripCounts = Array(suitableDelivery.length).fill(numOfTrips); // Заполняем массив количества поездок

  return deliveryCostPerTrip * numOfTrips; // Общая стоимость доставки для данного производства
});


    const resultTable = Object.entries(materialCosts).map(
      ([production, cost], index) => {
        return [
          production,
          formatNumberWithSpaces(distances[production]),
          formatNumberWithSpaces(cost),
          formatNumberWithSpaces(deliveryCosts[index]),
          formatNumberWithSpaces(tripCounts[index]),
          formatNumberWithSpaces(cost + deliveryCosts[index]),
          formatDate(updateDates[production]),
        ];
      }
    );

// Сравниваем длину массивов resultTable и resultTable2
let resultTableFinal;

if (resultTable.length === resultTable_2.length) {
  resultTableFinal = resultTable;  // Если длина одинаковая, сохраняем resultTable
} else {
  resultTableFinal = resultTable_2;  // Если длина разная, сохраняем resultTable2
}

    materialCosts = allProductions.reduce((acc, production) => {
      const items = productList
        .map(([productName, productQuantity]) => {
          const priceColumn = paymentMethod === "cash" ? 4 : 5;
          const stockEntry = stockSheet.find(
            (row) => row[1] === production && row[2] === productName
          );

          if (stockEntry) {
            const price = parseFloat(
              stockEntry[priceColumn].replace(/\s/g, "")
            );
            return { productName, price, quantity: productQuantity };
          } else {
            return null;
          }
        })
        .filter((item) => item !== null);

      if (items.length > 0) {
        acc[production] = items;
      }

      return acc;
    }, {});
    const optimalRoute = await findOptimalProductionRoute(
      allProductions,
      productList,
      materialCosts,
      numOfTrips,
      updateDates,
      deliveryCostsPerTrip,
      nearestDistanceOutsideKAD, 
      addCosts
    );
    printCachedDistances();
    return {
      data: sortArray(resultTableFinal),
      kadDistance: nearestDistanceOutsideKAD,
      addressesProductions,
      optimalData: optimalRoute, // Добавляем данные об оптимальном маршруте
      optimalDeliveryMethod: deliveryMethod,
    };
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}


function generateCacheKey(coord1, coord2) {
  return [coord1, coord2].sort().join("-"); // Сортируем координаты и соединяем их через "-"
}


async function getCachedDistance(coord1, coord2) {
  const key = generateCacheKey(coord1, coord2); // Используем функцию для генерации ключа
  //console.log(`Проверка кэша для ключа: ${key}`);
  
  if (cachedDistances[key] !== undefined) {
    //console.log(`Использование закэшированного расстояния для ключа: ${key}, значение: ${cachedDistances[key]}`);
    return cachedDistances[key];
  }

  //console.log(`Расстояние для ключа ${key} не найдено в кэше. Выполняется запрос к серверу...`);
  const distance = await fetchDistance(coord1, coord2); // Запрос к серверу
  cachedDistances[key] = distance; // Сохранение результата в кэш
  //console.log(`Сохранение расстояния в кэш для ключа: ${key}, значение: ${distance}`);
  
  return distance;
}



async function nearestExitKad(pointsKadSheet, destination) {
  try {
    let nearestExit = {
      distance: Number.MAX_VALUE,
      coordinates: null,
    };
    //console.log("Начинается поиск ближайшего выхода на КАД для точки назначения:", destination);

    // Используем кэш для вычисления расстояний
    await Promise.all(
      pointsKadSheet.slice(1).map(async (row) => {
        const coord = row[0];
        //console.log(`Вычисление расстояния от точки ${coord} до ${destination}`);
        const distance = await getCachedDistance(coord, destination); // Используем getCachedDistance

        if (isNaN(distance)) {
          //console.warn(`Некорректное значение расстояния для координаты ${coord}: ${distance}`);
          return Number.MAX_VALUE; // Возвращаем максимальное значение, чтобы исключить его из расчета минимального расстояния
        }

        if (distance < nearestExit.distance) {
          //console.log(`Обновление ближайшего выхода на КАД: от точки ${coord} с расстоянием ${distance}`);
          nearestExit = {
            distance: distance,
            coordinates: coord,
          };
        }

        return distance;
      })
    );

    if (nearestExit.distance === Infinity) {
      console.error("Все расстояния равны бесконечности, что-то пошло не так.");
      return {
        distance: Number.MAX_VALUE,
        coordinates: null,
      };
    }

    //console.log("Ближайший выход на КАД найден:", nearestExit);
    return nearestExit;
  } catch (error) {
    console.error("Ошибка в функции nearestExitKad:", error);
    return {
      distance: Number.MAX_VALUE,
      coordinates: null,
    };
  }
}



function filterProductionsByAllProducts(stockSheet, targetProducts) {
  const productionMap = stockSheet.reduce((map, row) => {
    const production = row[1];
    const product = row[2];
    if (!map[production]) map[production] = [];
    map[production].push(product);
    return map;
  }, {});

  //console.log("productionMap: ", productionMap);
  const filteredProductions = Object.keys(productionMap).filter((production) =>
    targetProducts.every((product) =>
      productionMap[production].includes(product)
    )
  );

  //console.log("filteredProductions: ", filteredProductions);
  return filteredProductions;
}


function filterNesk(stockSheet, targetProducts) {
  const productionMap = stockSheet.reduce((map, row) => {
    const production = row[1];
    const product = row[2];
    if (!map[production]) map[production] = [];
    map[production].push(product);
    return map;
  }, {});

  // Находим производства, которые покрывают все товары
  const fullCoverageProductions = Object.keys(productionMap).filter((production) =>
    targetProducts.every((product) => productionMap[production].includes(product))
  );

  if (fullCoverageProductions.length > 0) {
    return fullCoverageProductions;
  }

  // Если нет производств, покрывающих все товары, возвращаем те, что покрывают хотя бы часть
  const partialCoverageProductions = Object.keys(productionMap).filter((production) =>
    targetProducts.some((product) => productionMap[production].includes(product))
  );

  return partialCoverageProductions;
}



// Функция для сортировки массива по предпоследнему элементу (общей стоимости)
function sortArray(array) {
  return array.sort((a, b) => a[a.length - 2] - b[a.length - 2]);
}

function formatNumberWithSpaces(num) {
  if (num == null) {
    return "0"; // Или другой подходящий вариант
  }
  return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}

function formatDate(date) {
  if (!date || isNaN(date.getTime())) {
    return "Дата не определена";
  }
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}.${month}.${year}`;
}


async function listPricesJBI(productNames, paymentMethod, productionName) {
  try {
    if (cachedData === null) {
      cachedData = await fetchDataJBI();
    }
    const data = cachedData;
    const stockSheet = data.stockSheet;
    const priceColumn = paymentMethod === "cash" ? 4 : 5;

    // Получаем список цен на продукты
    const prices = productNames.map((productName) => {
      const productRow = stockSheet.find(
        (row) => row[1] === productionName && row[2] === productName
      );
      if (!productRow) {
        console.error(
          `Продукт ${productName} не найден в производстве ${productionName}`
        );
        return null; // Вернуть null, если продукт не найден, чтобы избежать остановки выполнения
      }
      return productRow[priceColumn];
    });

    // Используем глобальную переменную, если необходимо
    const cheapestMethod = globalCheapestMethod;

    // Возвращаем объект с ценами и методом доставки
    return {
      prices,
      cheapestMethod,
    };
  } catch (error) {
    console.error("Error fetching data:", error);
    throw error;
  }
}



function printCachedDistances() {
  //console.log("Содержимое кэша расстояний (cachedDistances):");
  Object.entries(cachedDistances).forEach(([key, value]) => {
    //console.log(`Ключ: ${key}, Значение: ${value}`);
  });
}




module.exports = {
  getProductionDataJBI,
  listPricesJBI,
  nearestExitKad,
};
