import React, { Component } from "react";
import axios from "axios";
import { fetchDeliveryOS,  fetchDeliveryJBI, fetchDeliveryJBIMoscow} from "../fetches";

import styledComponents from "../additionalComponents/StyledComponents";

const {
  Section,
  GreenButton,
  Input,
  RadioGroup,
  Label,
  Select,
  CoordinatesAndMapContainer,
  SubBlock,
  FormGroup,
  Icon,
} = styledComponents;

class BasicInfo1OS extends Component {
  constructor(props) {
    super(props);
    this.state = {
      clientCoordinates: "Здесь будут координаты",
      urlGoogleMaps: "http://www.example.com",
      locationInputValue: "",
      deliveryOptions: ["Оптимальный способ"], // По умолчанию "Оптимальный способ" уже в списке
      selectedDeliveryOption: "Оптимальный способ", // Значение по умолчанию — "Оптимальный способ"
      paymentMethod: "cash", // Значение по умолчанию — наличные
      locationType: "from-deal", // По умолчанию координаты
      isSelfPickup: false,
      isSelfPickupDisabled: false, // Новый флаг для блокировки чекбокса "Самовывоз"
      isOptionChangeDisabled: false, // Флаг блокировки выбора опций
    };
  }

  handleSelfPickupChange = () => {
    if (!this.state.isSelfPickupDisabled) {
      this.setState(
        (prevState) => ({
          isSelfPickup: !prevState.isSelfPickup,
        }),
        () => {
          const { isSelfPickup } = this.state;
  
          // Вызываем переданный проп для изменения isProductsSectionVisible
          if (!isSelfPickup && this.props.setIsProductsSectionVisible) {
            this.props.setIsProductsSectionVisible(false);
          } else if (isSelfPickup && this.props.setIsProductsSectionVisible) {
            this.props.setIsProductsSectionVisible(true);
          }
  
          this.props.onSelfPickupChange(isSelfPickup);
  
          if (!isSelfPickup) {
            // Показать всплывающее окно при снятии галочки с самовывоза
            alert("Не забудь поменять адрес");
            this.convertTosnoAddress();
          } else {
            this.updateVisibility();
          }
        }
      );
    }
  };
  
  

  convertTosnoAddress = () => {
    const tosnoAddress = "Владивосток, Россия";

    axios
      .get(
        `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
          tosnoAddress
        )}&format=json&limit=1`
      )
      .then((response) => {
        if (response.data && response.data.length > 0) {
          const { lat, lon } = response.data[0];
          const clientCoordinates = `${lat}, ${lon}`;
          const urlGoogleMaps = `https://www.google.com/maps?q=${lat},${lon}`;

          this.setState(
            {
              clientCoordinates,
              urlGoogleMaps,
            },
            this.updateVisibility
          );

          this.props.onCoordinatesChange(clientCoordinates);
        } else {
          alert('Адрес "Владивосток" не найден.');
        }
      })
      .catch((error) => {
        console.error("Ошибка при выполнении запроса к Nominatim API:", error);
        alert('Произошла ошибка при обработке адреса "Тосно".');
      });
  };

  updateVisibility = () => {
    const { clientCoordinates, selectedDeliveryOption, isSelfPickup } =
      this.state;
    if (
      isSelfPickup ||
      (clientCoordinates !== "Здесь будут координаты" && selectedDeliveryOption)
    ) {
      this.props.setIsProductsSectionVisible(true);
    } else {
      this.props.setIsProductsSectionVisible(false);
    }
  };

  loadDeliveryData() {
    const { selectedOption } = this.props; // Получаем выбранную опцию из пропсов
    // Условно выбираем функцию для загрузки данных
    const fetchDelivery =
  selectedOption === "Общестрой"
    ? fetchDeliveryOS
    : selectedOption === "ЖБИ"
    ? fetchDeliveryJBI
    : fetchDeliveryJBIMoscow; 

    // Загружаем данные
    fetchDelivery()
      .then((deliveryOptions) => {
        // Добавляем опцию "Оптимальный способ" к существующим опциям доставки и устанавливаем её в начало списка
        this.setState({
          deliveryOptions: ["Оптимальный способ", ...deliveryOptions],
        });
      })
      .catch((error) => {
        console.error("Ошибка при загрузке данных для доставки:", error);
      });
  }

  handleConvertCoordinates = () => {
    const { locationInputValue, locationType } = this.state;

    if (locationType === "from-deal") {
      const { dealCoordinates } = this.props; // Предполагается, что координаты сделки передаются в пропах
      console.log("Координаты сделки (dealCoordinates):", dealCoordinates); // Логируем координаты
      const normalizedDealCoordinates = dealCoordinates
        ?.replace(/\s*,\s*/g, ",")
        .replace(/\s+/g, " ")
        .trim();

      // Проверяем, равны ли координаты сделки указанным
      if (normalizedDealCoordinates === "69.0732199, 33.4068468") {
        this.setState(
          {
            isSelfPickup: true, // Устанавливаем самовывоз
          },
          () => {
            this.props.onSelfPickupChange(this.state.isSelfPickup);
            this.updateVisibility();
          }
        );
      } else if (normalizedDealCoordinates) {
        this.setState(
          {
            clientCoordinates: dealCoordinates,
            urlGoogleMaps: `https://www.google.com/maps?q=${dealCoordinates}`,
          },
          this.updateVisibility
        );
        this.props.onCoordinatesChange(dealCoordinates);
      } else {
        alert("Координаты сделки не найдены.");
      }
    } else if (locationType === "coordinates") {
      const coordinatesRegex =
        /^-?([1-8]?[1-9]|[1-9]0)\.\d+, ?-?((1[0-7][0-9]|[1-9]?[0-9])\.\d+|180\.0+)$/;
      if (coordinatesRegex.test(locationInputValue)) {
        const clientCoordinates = locationInputValue;
        this.setState(
          {
            clientCoordinates,
            urlGoogleMaps: `https://www.google.com/maps?q=${locationInputValue}`,
          },
          this.updateVisibility
        );
        this.props.onCoordinatesChange(clientCoordinates);
      } else {
        alert("Введите корректные координаты.");
      }
    } else if (locationType === "address") {
      axios
        .get(
          `https://nominatim.openstreetmap.org/search?q=${encodeURIComponent(
            locationInputValue
          )}&format=json&limit=1`
        )
        .then((response) => {
          if (response.data && response.data.length > 0) {
            const { lat, lon } = response.data[0];
            const clientCoordinates = `${lat}, ${lon}`;
            const urlGoogleMaps = `https://www.google.com/maps?q=${lat},${lon}`;
            this.setState(
              {
                clientCoordinates,
                urlGoogleMaps,
              },
              this.updateVisibility
            );
            this.props.onCoordinatesChange(clientCoordinates);
          } else {
            alert("Адрес не найден.");
          }
        })
        .catch((error) => {
          console.error(
            "Ошибка при выполнении запроса к Nominatim API:",
            error
          );
          alert("Произошла ошибка при обработке адреса.");
        });
    } else {
      alert("Выберите тип местоположения.");
    }
  };

  handleInputChange = (event) => {
    this.setState({ locationInputValue: event.target.value });
  };

  handleDeliveryChange = (event) => {
    const selectedDeliveryOption = event.target.value;
    this.setState(
      {
        selectedDeliveryOption,
      },
      this.updateVisibility
    );

    if (selectedDeliveryOption === "Оптимальный способ") {
      console.log("Выбран Оптимальный способ");
      // Вызов функции для вычисления оптимального маршрута или другая логика
    }
    this.props.onDeliveryChange(selectedDeliveryOption);
  };

  handlePaymentMethodChange = (event) => {
    const paymentMethod = event.target.value;
    this.setState({ paymentMethod });
    this.props.onPaymentChange(paymentMethod);
  };

  handleLocationTypeChange = (event) => {
    const locationType = event.target.value;
    this.setState({ locationType, locationInputValue: "" }); // Очистить значение ввода при переключении типа
  };

  // Функция для форматирования координат до шести десятичных знаков
  formatCoordinates = (coordinates) => {
    if (!coordinates) return "";
    const coordsArray = coordinates.split(",");
    if (coordsArray.length !== 2) return "";

    const lat = parseFloat(coordsArray[0].trim()).toFixed(6);
    const lon = parseFloat(coordsArray[1].trim()).toFixed(6);

    if (isNaN(lat) || isNaN(lon)) return "";

    return `${lat}, ${lon}`;
  };

  componentDidMount() {
    this.loadDeliveryData();
  }

  componentDidUpdate(prevProps) {
    // Если selectedOption изменился
    if (prevProps.selectedOption !== this.props.selectedOption) {
      this.loadDeliveryData();

      // Если выбран "Общестрой", меняем форму оплаты на "наличные"
      if (this.props.selectedOption === "Общестрой") {
        this.setState({ paymentMethod: "cash" });
      } else {
        // Если выбрана другая опция, меняем форму оплаты на "bank-transfer"
        if (this.state.paymentMethod === "cash") {
          this.setState({ paymentMethod: "bank-transfer" });
        }
      }
    }
  }

  render() {
    const {
      clientCoordinates,
      urlGoogleMaps,
      deliveryOptions,
      locationType,
      paymentMethod,
      isSelfPickup,
      isSelfPickupDisabled,
      selectedDeliveryOption,
    } = this.state;

    return (
      <Section $isVisible={true}>
        <h2>Базовая информация</h2>
        <FormGroup>
          <SubBlock>
            <Label>
              <input
                type="checkbox"
                checked={isSelfPickup}
                onChange={this.handleSelfPickupChange}
                disabled={isSelfPickupDisabled} // Блокируем чекбокс, если нужно
              />
              Самовывоз
            </Label>
          </SubBlock>

          {!isSelfPickup && (
            <>
              <SubBlock>
                <RadioGroup>
                  <div>
                    <input
                      type="radio"
                      id="from-deal"
                      name="location"
                      value="from-deal"
                      checked={locationType === "from-deal"}
                      onChange={this.handleLocationTypeChange}
                    />
                    <Label htmlFor="from-deal">Подтягивать из сделки</Label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="coordinates"
                      name="location"
                      value="coordinates"
                      checked={locationType === "coordinates"}
                      onChange={this.handleLocationTypeChange}
                    />
                    <Label htmlFor="coordinates">Координаты</Label>
                  </div>
                  <div>
                    <input
                      type="radio"
                      id="address"
                      name="location"
                      value="address"
                      checked={locationType === "address"}
                      onChange={this.handleLocationTypeChange}
                    />
                    <Label htmlFor="address">Адрес</Label>
                  </div>
                </RadioGroup>
                {locationType === "from-deal" ? (
                  <div style={{ color: "green" }}>
                    *будут подставлены координаты из сделки
                  </div>
                ) : (
                  <Input
                    type="text"
                    placeholder={
                      locationType === "coordinates"
                        ? "12.345678, 90.123456"
                        : "Введите адрес"
                    }
                    value={this.state.locationInputValue}
                    onChange={this.handleInputChange}
                  />
                )}
                <GreenButton onClick={this.handleConvertCoordinates}>
                  Преобразовать
                </GreenButton>
                <CoordinatesAndMapContainer>
                  <Label style={{ color: "#888" }}>
                    {this.formatCoordinates(clientCoordinates)}
                  </Label>
                  <a
                    id="urlGoogleMaps"
                    href={urlGoogleMaps}
                    target="_blank"
                    rel="noopener noreferrer"
                    style={{
                      padding: "0.5rem 0.625rem",
                      backgroundColor: "transparent",
                      color: "var(--BLUE_BUTTON)",
                      borderRadius: "4px",
                      border: "none",
                      cursor: "pointer",
                      transition: "background-color 0.3s",
                    }}
                    onMouseEnter={(e) => {
                      e.currentTarget.style.color = "var(--BLUE_BUTTON_DARK)";
                    }}
                    onMouseLeave={(e) => {
                      e.currentTarget.style.color = "var(--BLUE_BUTTON)";
                    }}
                  >
                    <span>
                      <Icon
                        className="material-icons"
                      >
                        location_on
                      </Icon>
                    </span>
                  </a>
                </CoordinatesAndMapContainer>
              </SubBlock>

              <SubBlock>
                <Label>Способ доставки</Label>
                <Select
                  onChange={this.handleDeliveryChange}
                  value={selectedDeliveryOption}
                >
                  {deliveryOptions.map((option, index) => (
                    <option key={index} value={option}>
                      {option}
                    </option>
                  ))}
                </Select>
                {/* Надпись, если выбран "Оптимальный способ" */}
                {selectedDeliveryOption === "Оптимальный способ" && (
                  <p
                    style={{
                      color: "green",
                      marginTop: "5px",
                      textAlign: "center",
                    }}
                  >
                    *будет выбран самый бюджетный способ доставки
                  </p>
                )}
              </SubBlock>
            </>
          )}
          <SubBlock>
            <Label htmlFor="payment-method">Форма оплаты</Label>
            <Select
              id="payment-method"
              name="payment-method"
              onChange={this.handlePaymentMethodChange}
              value={paymentMethod}
            >
              <option value="cash">Наличные</option>
              <option value="bank-transfer">Безналичный расчет</option>
            </Select>
          </SubBlock>
        </FormGroup>
      </Section>
    );
  }
}

export default BasicInfo1OS;
