import React, { useState } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';

// Styled компонент для контейнера
const TooltipContainer = styled.div`
  position: relative;
  display: inline-block;
  cursor: pointer;
`;

// Styled компонент для текста подсказки
const TooltipText = styled.div`
  visibility: ${({ visible }) => (visible ? 'visible' : 'hidden')};
  background-color: #555;
  color: #fff;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  position: absolute;
  z-index: 1000;
  top: ${({ position }) => position.top}px;
  left: ${({ position }) => position.left}px;
  white-space: pre-wrap;
  max-width: 400px; /* Ограничиваем ширину текста */
  word-wrap: break-word; /* Перенос длинных слов */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Добавляем тень */

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: -5px;
    transform: translateY(-50%);
    border-width: 5px;
    border-style: solid;
    border-color: transparent #555 transparent transparent;
  }
`;

// Компонент для рендеринга подсказки в портал
const TooltipPortal = ({ children, position }) => {
  return ReactDOM.createPortal(
    <TooltipText position={position} visible={!!children}>
      {children}
    </TooltipText>,
    document.body
  );
};

// Обёртка для элементов, на которые нужно добавить подсказки
const TooltipWrapper = ({ text, children }) => {
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState({ top: 0, left: 0 });

  // Обработчик при наведении
  const handleMouseEnter = (e) => {
    const rect = e.target.getBoundingClientRect();
    setPosition({
      top: rect.top + window.scrollY, // Учитываем прокрутку страницы
      left: rect.right + window.scrollX, // Учитываем прокрутку страницы
    });
    setVisible(true);
  };

  // Обработчик при уходе мыши
  const handleMouseLeave = () => {
    setVisible(false);
  };

  return (
    <TooltipContainer
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {visible && <TooltipPortal position={position}>{text}</TooltipPortal>}
    </TooltipContainer>
  );
};

export default TooltipWrapper;
