import React, { useEffect, useState, useRef, useCallback } from "react";
import { Tooltip } from "react-tooltip";
import ReactDOM from "react-dom"; // Добавьте этот импорт
import {
  fetchDictOfTypesSubsectionsNamesOS,
  fetchMassVolumeOS,
  fetchAnalog,
  fetchDictOfTypesSubsectionsNamesJBI,
  fetchMassVolumeJBI,
  fetchAnalogJBI,
  fetchDictOfTypesSubsectionsNamesJBIMoscow,
  fetchMassVolumeJBIMoscow,
  fetchAnalogJBIMoscow,
} from "../fetches";
import Select from "react-select";
import { transliterate } from "transliteration";
import styledComponents from "../additionalComponents/StyledComponents";

const { Section, ButtonGroupWrapper, Table, BlueButton, RedButton, PurpleButton, Icon, Input } =
  styledComponents;


const Products2OS = ({
  isProductsSectionVisible,
  onTotalWeightChange,
  onTotalVolumeChange,
  onNamesAndQuantitiesChange,
  toggleCostComparisonVisibility,
  toggleCommercialOfferVisibility,
  selectedOption,
  onAnalogDataChange,
}) => {
  const [massVolumeData, setMassVolumeData] = useState({});
  const [isTooltipVisible, setIsTooltipVisible] = useState(false); // eslint-disable-line
  const [tooltipContent, setTooltipContent] = useState(""); // eslint-disable-line
  const tooltipRef = useRef(null); // eslint-disable-line
  const tooltipHideTimeoutRef = useRef(null); // Реф для хранения таймера
  const [analogData, setAnalogData] = useState({}); // Состояние для данных аналогов
  const rowRefs = useRef([]);

  const [rows, setRows] = useState([
    {
      selectedItem: null,
      quantity: "",
      materialSubtype: "",
      materialMass: "0",
      materialSummMass: "",
      selectedSubsection: "",
      itemNameOptions: [],
      selectedMaterialType: null,
      subsectionOptions: [],
      materialVolume: "0",
      materialSummVolume: "0",
    },
  ]);

  const [itemOptions, setItemOptions] = useState([]); // Список всех наименований
  const debounceTimerRef = useRef(null); // Таймер для дебаунса
  let top5Items;
  const updateTotalMassAndVolume = useCallback(() => {
    if (rows.every(isValidRow)) {
      const itemMassCells = document.querySelectorAll(".item-mass");
      const itemVolumeCells = document.querySelectorAll(".item-volume");
      let totalSumMass = 0;
      let totalSumVolume = 0;

      itemMassCells.forEach((cell, index) => {
        const row = cell.closest("tr");
        const quantity =
          parseFloat(row.querySelector('input[name="quantity[]"]').value) || 0;
        const itemMass = parseFloat(cell.textContent) || 0;
        const itemVolume = parseFloat(itemVolumeCells[index].textContent) || 0;

        const totalMassCell = row.querySelector(".total-mass");
        const totalMass = quantity * itemMass;
        totalMassCell.textContent = totalMass.toFixed(2);
        totalSumMass += totalMass;

        const totalVolumeCell = row.querySelector(".total-volume");
        const totalVolume = quantity * itemVolume;
        totalVolumeCell.textContent = totalVolume.toFixed(2);
        totalSumVolume += totalVolume;
      });

      document.getElementById("total-sum-mass").textContent =
        totalSumMass.toFixed(2);
      onTotalWeightChange(totalSumMass);

      document.getElementById("total-sum-volume").textContent =
        totalSumVolume.toFixed(2);
      onTotalVolumeChange(totalSumVolume);

      toggleCostComparisonVisibility(true);
    } else {
      toggleCostComparisonVisibility(false);
      toggleCommercialOfferVisibility(false);
    }
  }, [
    rows,
    onTotalWeightChange,
    onTotalVolumeChange,
    toggleCostComparisonVisibility,
    toggleCommercialOfferVisibility,
  ]);

  const updateNamesAndQuantities = useCallback(() => {
    if (rows.every(isValidRow)) {
      const namesAndQuantities = rows.map((row) => [
        row.selectedItem ? row.selectedItem.label : "",
        row.quantity ? parseFloat(row.quantity) : 0,
        row.unit || "шт", // Добавляем единицу измерения в массив
      ]);
      onNamesAndQuantitiesChange(namesAndQuantities);
    }
  }, [rows, onNamesAndQuantitiesChange]);

  console.log();

  const debouncedUpdate = useCallback(() => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }
    debounceTimerRef.current = setTimeout(() => {
      updateTotalMassAndVolume();
      updateNamesAndQuantities();
    }, 300); // Интервал задержки (в мс), можно настроить
  }, [updateTotalMassAndVolume, updateNamesAndQuantities]);

  useEffect(() => {
    debouncedUpdate();
  }, [rows, debouncedUpdate]);

  const handleQuantityChange = (event, index) => {
    const { value } = event.target;
    const cleanedValue = value.replace(/[^\d]/g, "");
    const finalValue =
      cleanedValue !== ""
        ? Math.min(parseInt(cleanedValue, 10), 1000000).toString()
        : "";

    const itemName = rows[index].selectedItem
      ? rows[index].selectedItem.value
      : null;
    const itemData = massVolumeData[itemName] || { mass: 0, volume: 0 };

    console.log(
      "Обновление количества:",
      finalValue,
      "Для товара:",
      itemName,
      "Данные товара:",
      itemData
    );

    setRows((rows) =>
      rows.map((row, i) => {
        if (i === index) {
          return {
            ...row,
            quantity: finalValue,
            materialMass: itemData.mass.toString(), // Обновляем массу изделия
            materialVolume: itemData.volume.toString(), // Обновляем объем изделия
            materialSummMass: (
              itemData.mass * parseFloat(finalValue || 0)
            ).toFixed(2), // Обновляем общую массу
            materialSummVolume: (
              itemData.volume * parseFloat(finalValue || 0)
            ).toFixed(2), // Обновляем общий объем
          };
        }
        return row;
      })
    );

    debouncedUpdate(); // Обновление данных через дебаунс
  };

  const handleItemChange = (selectedOption, index) => {
    const itemData = massVolumeData[
      selectedOption ? selectedOption.value : null
    ] || { mass: 0, volume: 0 };
    console.log(
      "Изменение товара:",
      selectedOption,
      "Данные товара:",
      itemData
    ); // Логируем данные

    setRows((rows) =>
      rows.map((row, i) => {
        if (i === index) {
          return {
            ...row,
            selectedItem: selectedOption,
            materialMass: itemData.mass.toString(), // Обновляем массу изделия
            materialVolume: itemData.volume.toString(), // Обновляем объем изделия
            materialSummMass: (
              itemData.mass * parseFloat(row.quantity || 0)
            ).toFixed(2), // Обновляем общую массу
            materialSummVolume: (
              itemData.volume * parseFloat(row.quantity || 0)
            ).toFixed(2), // Обновляем общий объем
          };
        }
        return row;
      })
    );
    debouncedUpdate();
  };

  useEffect(() => {
    // Определяем, какие функции использовать в зависимости от selectedOption
    const fetchDictOfTypesSubsectionsNames =
      selectedOption === "Общестрой"
        ? fetchDictOfTypesSubsectionsNamesOS
        : selectedOption === "ЖБИ"
        ? fetchDictOfTypesSubsectionsNamesJBI
        : fetchDictOfTypesSubsectionsNamesJBIMoscow; // Для варианта "ЖБИ Москва"

    const fetchMassVolume =
      selectedOption === "Общестрой"
        ? fetchMassVolumeOS
        : selectedOption === "ЖБИ"
        ? fetchMassVolumeJBI
        : fetchMassVolumeJBIMoscow; // Для варианта "ЖБИ Москва"

    const fetchAnalogData =
      selectedOption === "Общестрой"
        ? fetchAnalog
        : selectedOption === "ЖБИ"
        ? fetchAnalogJBI
        : fetchAnalogJBIMoscow; // Для варианта "ЖБИ Москва"

    fetchDictOfTypesSubsectionsNames()
      .then((data) => {
        const allItemOptions = new Set(); // Используем Set для удаления дубликатов
        Object.keys(data).forEach((typeKey) => {
          Object.keys(data[typeKey]).forEach((subsectionKey) => {
            data[typeKey][subsectionKey].forEach((item) => {
              allItemOptions.add(item[0]); // Добавляем только уникальные элементы
            });
          });
        });
        setItemOptions(
          Array.from(allItemOptions).map((item) => ({
            value: item,
            label: item,
          }))
        ); // Преобразуем Set обратно в массив и сохраняем
      })
      .catch((error) => console.error("Ошибка при загрузке данных:", error));

    fetchMassVolume()
      .then((data) => {
        setMassVolumeData(data);
      })
      .catch((error) => {
        console.error(
          "Ошибка при загрузке данных из fetchMassVolumeOS:",
          error
        );
      });

    fetchAnalogData()
      .then((data) => {
        console.log("Все полученные данные:", data); // Выводим все данные в консоль
        const analogMap = data.reduce((acc, item) => {
          if (!acc[item.itemName]) {
            acc[item.itemName] = []; // Если для itemName еще нет массива, создаем его
          }
          acc[item.itemName].push(item); // Добавляем каждый объект в соответствующий массив
          return acc;
        }, {});
        setAnalogData(analogMap); // Сохраняем данные в состоянии
      })
      .catch((error) => {
        console.error("Ошибка при загрузке данных аналогов:", error);
      });
  }, [selectedOption]);

  const isValidRow = (row) => {
    return row.selectedItem && row.quantity && parseFloat(row.quantity) > 0;
  };

  const addRow = () => {
    setRows((prevRows) => {
      const newRow = {
        selectedItem: null,
        quantity: "",
        materialSubtype: "",
        materialMass: "0",
        materialSummMass: "0",
        selectedSubsection: "",
        itemNameOptions: [],
        selectedMaterialType: null,
        subsectionOptions: [],
        materialVolume: "0",
        materialSummVolume: "0",
      };
      const updatedRows = [...prevRows, newRow];

      // Увеличиваем массив рефов, добавляя реф для новой строки
      rowRefs.current = [...rowRefs.current, React.createRef()];
      return updatedRows;
    });
  };

  const deleteRow = (index) =>
    setRows((rows) => rows.filter((_, i) => i !== index));

  const copyRow = (index) => {
    setRows((rows) => {
      const newRow = { ...rows[index] };
      return [...rows.slice(0, index + 1), newRow, ...rows.slice(index + 1)];
    });
  };

  const parsePrice = (priceString) => {
    return parseFloat(priceString.replace(/\s/g, "").replace(",", "."));
  };

  const formatPrice = (price) => {
    return parseFloat(price.replace(/\s/g, "")).toLocaleString("ru-RU", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const getTooltipContent = (itemName, requiredQuantity = 1) => {
    if (!itemName || !analogData[itemName]) return "Нет данных о аналогах";

    const analogs = analogData[itemName];
    if (Array.isArray(analogs)) {
      const referenceDensity = analogs[0].density
        ? parseFloat(analogs[0].density)
        : null;
      const referenceCategory = analogs[0].analogCategory || null;
      const referenceThickness = analogs[0].thickness
        ? parseFloat(analogs[0].thickness)
        : null;
      const referenceExtraThickness = analogs[0].extraThickness
        ? parseInt(analogs[0].extraThickness)
        : null;

      const minPrice = analogs.reduce((min, current) => {
        const currentPrice = parsePrice(current.price);
        return currentPrice < min ? currentPrice : min;
      }, parsePrice(analogs[0].price));

      const referenceAnalog = analogs.find(
        (analog) => parsePrice(analog.price) === minPrice
      );
      const referenceManufacture = referenceAnalog.manufacture || "нет данных";

      const area2 = referenceAnalog.area
        ? parseFloat(referenceAnalog.area.replace(",", "."))
        : null;
      const quantity2 = referenceAnalog.packQuantity
        ? parseInt(referenceAnalog.packQuantity)
        : null;
      const currentPricePerSquareMeter = area2
        ? (minPrice / area2).toFixed(0)
        : null;

      // Расчет цены за лист для основного товара
      const mainPricePerSheet = quantity2
        ? (minPrice / quantity2).toFixed(0)
        : "нет данных";

      // Количество упаковок основного товара для покрытия требуемой площади
      const mainPackagesNeeded = area2
        ? Math.ceil((requiredQuantity * area2) / area2)
        : "нет данных";

      const matchingAnalogs = Object.values(analogData)
        .flat()
        .filter((analog) => {
          if (analog.itemName === itemName) return false;

          const density = analog.density ? parseFloat(analog.density) : null;
          const thickness = analog.thickness
            ? parseFloat(analog.thickness)
            : null;
          const extraThickness = analog.extraThickness
            ? parseInt(analog.extraThickness)
            : null;

          if (referenceCategory && referenceDensity) {
            const referenceCategories = referenceCategory
              .split(",")
              .map((c) => c.trim());
            const analogCategories = analog.analogCategory
              .split(",")
              .map((c) => c.trim());
            const hasMatchingCategory = referenceCategories.some((category) =>
              analogCategories.includes(category)
            );
            return (
              hasMatchingCategory &&
              density >= referenceDensity - 10 &&
              density <= referenceDensity + 10 &&
              extraThickness === referenceExtraThickness
            );
          } else if (referenceThickness) {
            return (
              thickness >= referenceThickness && thickness <= referenceThickness
            );
          }
          return false;
        });

      const sortedAnalogs = matchingAnalogs
        .map((item) => {
          const price = parsePrice(item.price);
          const area = item.area
            ? parseFloat(item.area.replace(",", "."))
            : null;
          const pricePerSquareMeter = area ? price / area : null;
          return {
            ...item,
            pricePerSquareMeter: pricePerSquareMeter || price,
          };
        })
        .sort((a, b) => a.pricePerSquareMeter - b.pricePerSquareMeter);

      const uniqueAnalogs = sortedAnalogs.reduce((acc, current) => {
        const existingItem = acc.find(
          (item) => item.itemName === current.itemName
        );
        if (
          !existingItem ||
          current.pricePerSquareMeter < existingItem.pricePerSquareMeter
        ) {
          return acc
            .filter((item) => item.itemName !== current.itemName)
            .concat(current);
        }
        return acc;
      }, []);

      top5Items = uniqueAnalogs.slice(0, 3);
      // Проверяем, изменились ли данные

      // Шаблон для случая, когда analogCategory отсутствует
      if (!referenceAnalog.analogCategory) {
        const uniqueAnalogs = [];
        const matchingAnalogs = Object.values(analogData)
          .flat()
          .filter((analog) => {
            // Проверка условий фильтрации
            const isDifferentItem = analog.itemName !== itemName;
            const hasNoCategory = !analog.analogCategory;
            const hasMatchingThickness =
              analog.thickness && referenceThickness
                ? parseFloat(analog.thickness) ===
                  parseFloat(referenceThickness)
                : false;
            // Вывод отладочной информации
            /*console.log(
  "Толщина аналога:", analog.thickness,
  "Толщина товара:", referenceThickness,
  "Тип данных толщины аналога:", typeof analog.thickness,
  "Тип данных толщины товара:", typeof referenceThickness
);*/

            // Оставляем только аналоги без категории и с совпадающей толщиной
            return isDifferentItem && hasNoCategory && hasMatchingThickness;
          })
          .map((analog) => {
            const price = parsePrice(analog.price); // Применяем parsePrice, чтобы убрать пробелы

            // Рассчитываем цену за лист
            const pricePerSheet = price;

            // Вычисление выгоды на объем
            const totalSavings = (minPrice - price) * requiredQuantity;
            const savingsColor = totalSavings > 0 ? "green" : "red";

            return {
              itemName: analog.itemName,
              manufacture: analog.manufacture || "нет данных",
              pricePerSheet:
                pricePerSheet !== "нет данных" ? pricePerSheet : "нет данных",
              priceDifferencePerSheet:
                mainPricePerSheet && pricePerSheet !== "нет данных"
                  ? mainPricePerSheet - parseFloat(pricePerSheet)
                  : "нет данных",
              totalSavings,
              savingsColor,
            };
          })
          .filter((analog) => {
            const isDuplicate = uniqueAnalogs.some(
              (unique) => unique.itemName === analog.itemName
            );
            if (!isDuplicate) {
              uniqueAnalogs.push(analog);
              return true;
            }
            return false;
          })
          .sort((a, b) => a.pricePerSheet - b.pricePerSheet) // Сортируем по цене за лист
          .slice(0, 3); // Оставляем три самых бюджетных аналога

        // Формируем контент для подсказки
        const analogContent = matchingAnalogs
          .map(
            (analog) => `
    ${analog.itemName}<br>
    <strong>${analog.manufacture.toUpperCase()}</strong><br>
    ЦЕНА ЗА ЛИСТ: ${
      analog.pricePerSheet !== "нет данных"
        ? `${analog.pricePerSheet} руб.`
        : "нет данных"
    }<br>
    ВЫГОДА НА ОБЪЕМ: <span style="color: ${analog.savingsColor};">${
              analog.totalSavings !== "нет данных"
                ? `${analog.totalSavings} руб`
                : "нет данных"
            }</span><br>
    ___________
  `
          )
          .join("<br><br>");

        // Основной контент подсказки
        return `
    <strong>${referenceAnalog.itemName}</strong><br>
    Толщина: <strong>${referenceThickness || "нет данных"} мм</strong><br>
    ${referenceManufacture.toUpperCase()}<br>
    ЦЕНА ЗА ЛИСТ: ${formatPrice(minPrice.toString())} руб.<br>
    __________<br>
    <strong>АНАЛОГИ:</strong><br>
    ${analogContent || "Нет подходящих аналогов"}
  `;
      }

      const header = `
        <strong>${referenceAnalog.itemName}<br></strong>
        Плотность: ${referenceAnalog.density || "нет данных"} кг/м³<br>
        Кол-во м² требуемого объема: <strong>${(
          requiredQuantity * area2
        ).toFixed(2)} м²</strong><br>
        <strong>${
          referenceAnalog.manufacture.toUpperCase() || "нет данных"
        }:</strong><br>
      `;

      const priceTable = `
      <table border="1" cellpadding="5" cellspacing="0" style="border-collapse: collapse; text-align: center;">
        <thead>
          <tr>
            <th style="color: #000000;">Уп-ка</th>
            <th style="color: #000000;">М²</th>
            <th style="color: #000000;">Лист</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>${formatPrice(minPrice.toString())}</td>
            <td>${currentPricePerSquareMeter || "нет данных"}</td>
            <td>${mainPricePerSheet} руб.</td>
          </tr>
        </tbody>
      </table>
      <br>________<br>
      <br><strong>АНАЛОГИ:</strong><br>
      `;

      const tooltipContent = top5Items
        .map((item, index) => {
          const area = item.area
            ? parseFloat(item.area.replace(",", "."))
            : null;
          const pricePerSquareMeter = area
            ? (parsePrice(item.price) / area).toFixed(0)
            : null;
          const packagesNeeded = area
            ? Math.ceil((requiredQuantity * area2) / area)
            : "нет данных";

          const priceDifferencePerPack = minPrice - parsePrice(item.price);
          const priceDifferencePerSquareMeter =
            currentPricePerSquareMeter && pricePerSquareMeter
              ? currentPricePerSquareMeter - pricePerSquareMeter
              : 0;

          // Вычисление цены за лист для каждого аналога
          const pricePerSheet = quantity2
            ? (parsePrice(item.price) / quantity2).toFixed(0)
            : "нет данных";
          const priceDifferencePerSheet =
            mainPricePerSheet && pricePerSheet
              ? mainPricePerSheet - pricePerSheet
              : 0;

          // Вычисление выгоды на объем
          const totalSavings =
            mainPackagesNeeded !== "нет данных" &&
            packagesNeeded !== "нет данных"
              ? mainPackagesNeeded * minPrice -
                packagesNeeded * parsePrice(item.price)
              : "нет данных";
          const savingsColor = totalSavings > 0 ? "green" : "red";

          const isCheapest = index === 0;
          return `
          <${isCheapest ? "span" : "span"}>
            <strong><span style="${isCheapest ? "color: green;" : ""}">${
            item.itemName
          }</span><br></strong>
            Плотность: ${item.density || "нет данных"} кг/м³<br>
            ${packagesNeeded} упаковок на такое же кол-во м²<br>
            <strong>${item.manufacture.toUpperCase() || "нет данных"}:</strong>
            <table border="1" cellpadding="5" cellspacing="0" style="border-collapse: collapse; text-align: center; width: 100%;">
              <thead>
                <tr>
                  <th style="color: #000000;">Уп-ка</th>
                  <th style="color: #000000;">М²</th>
                  <th style="color: #000000;">Лист</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>${formatPrice(item.price)} (${
            priceDifferencePerPack < 0
              ? `+${Math.abs(priceDifferencePerPack)}`
              : `-${priceDifferencePerPack}`
          } руб.)</td>
                  <td>
                    <strong>
                      ${pricePerSquareMeter || "нет данных"} 
                      (<span style="color: green;">
                        ${
                          priceDifferencePerSquareMeter < 0
                            ? `+${Math.abs(priceDifferencePerSquareMeter)}`
                            : `-${priceDifferencePerSquareMeter}`
                        } руб.
                      </span>)
                    </strong>
                  </td>
                  <td>${pricePerSheet} руб. (${
            priceDifferencePerSheet < 0
              ? `+${Math.abs(priceDifferencePerSheet)}`
              : `-${priceDifferencePerSheet}`
          } руб.)</td>
                </tr>
              </tbody>
            </table>
          ВЫГОДА НА ОБЪЕМ: <span style="color: ${savingsColor};">${
            totalSavings !== "нет данных" ? `${totalSavings} руб` : "нет данных"
          }</span><br>
          _____________
          </${isCheapest ? "span" : "span"}>
        `;
        })
        .join("<br><br>");

      return (
        header + priceTable + (tooltipContent || "Нет подходящих аналогов")
      );
    }
    return `Цена: ${formatPrice(analogs.price)}<br>Плотность: ${
      analogs.density || "нет данных"
    }<br>Толщина: ${analogs.thickness || "нет данных"}<br>Категория аналогов: ${
      analogs.analogCategory || "нет данных"
    }`;
  };

  const tooltipPortal = ReactDOM.createPortal(
    <div
      onMouseEnter={() => {
        // Очищаем таймер скрытия при наведении на подсказку
        if (tooltipHideTimeoutRef.current) {
          clearTimeout(tooltipHideTimeoutRef.current);
        }
        setIsTooltipVisible(true);
      }}
      onMouseLeave={() => {
        // Устанавливаем задержку перед скрытием подсказки
        tooltipHideTimeoutRef.current = setTimeout(() => {
          setIsTooltipVisible(false);
        }, 1000);
      }}
      onWheel={(e) => e.stopPropagation()} // Останавливаем событие прокрутки для подсказки
      style={{
        position: "relative",
      }}
    >
      <Tooltip
        id="tooltip"
        place="top"
        effect="solid"
        wrapper="div"
        style={{
          position: "absolute",
          zIndex: 9999,
        }}
        delayShow={300}
        render={({ content }) => (
          <div
            onMouseEnter={() => {
              // Останавливаем таймер скрытия, если мышь снова наводится на подсказку
              if (tooltipHideTimeoutRef.current) {
                clearTimeout(tooltipHideTimeoutRef.current);
              }
              setIsTooltipVisible(true);
            }}
            onMouseLeave={() => {
              // Запускаем таймер на 1 секунду перед скрытием подсказки
              tooltipHideTimeoutRef.current = setTimeout(() => {
                setIsTooltipVisible(false);
              }, 1000);
            }}
            onWheel={(e) => e.stopPropagation()} // Остановка всплывающего события прокрутки
            dangerouslySetInnerHTML={{ __html: content }}
            style={{
              padding: "10px",
              maxWidth: "600px",
            }}
          />
        )}
      />
    </div>,
    document.body
  );

  useEffect(() => {
    // Очистка таймера при размонтировании компонента
    return () => {
      if (tooltipHideTimeoutRef.current) {
        clearTimeout(tooltipHideTimeoutRef.current);
      }
    };
  }, []);

  const customStyles = {
    control: (provided) => ({
      ...provided,
      width: "auto", // Ширина подстраивается под содержимое
      minWidth: "100px", // Минимальная ширина
      minHeight: "40px",
    }),
    menu: (provided) => ({
      ...provided,
      width: "auto", // Ширина выпадающего списка подстраивается
      minWidth: "100px", // Минимальная ширина выпадающего списка
    }),
    valueContainer: (provided) => ({
      ...provided,
      width: "auto", // Контейнер значений также подстраивается
      minWidth: "100px", // Минимальная ширина контейнера
      padding: "0 8px", // Добавляем отступы для красоты
    }),
    singleValue: (provided) => ({
      ...provided,
      whiteSpace: "normal", // Позволяет тексту переноситься на новую строку
    }),
    menuPortal: (provided) => ({
      ...provided,
      zIndex: 9999, // Устанавливаем высокий z-index для портала
    }),
  };

  const qwertyToYtsuken = {
    q: "й",
    w: "ц",
    e: "у",
    r: "к",
    t: "е",
    y: "н",
    u: "г",
    i: "ш",
    o: "щ",
    p: "з",
    "[": "х",
    "]": "ъ",
    a: "ф",
    s: "ы",
    d: "в",
    f: "а",
    g: "п",
    h: "р",
    j: "о",
    k: "л",
    l: "д",
    ";": "ж",
    "'": "э",
    z: "я",
    x: "ч",
    c: "с",
    v: "м",
    b: "и",
    n: "т",
    m: "ь",
    ",": "б",
    ".": "ю",
    "`": "ё",
  };

  // Функция для транслитерации с латинской раскладки (QWERTY) на русскую (ЙЦУКЕН)
  const transliterateFromQwertyToYtsuken = (input) => {
    return input
      .split("")
      .map((char) => qwertyToYtsuken[char] || char)
      .join("");
  };

  const filterOption = (option, inputValue) => {
    let searchTerms = inputValue.toLowerCase().split(" ").filter(Boolean);
    const label = option.label.toLowerCase();

    // Транслитерация с русского на английский для каждого слова
    let searchTermsTranslit = searchTerms.map((term) => transliterate(term));
    let keyboardLayout = searchTerms.map((term) =>
      transliterateFromQwertyToYtsuken(term)
    );

    // Для каждого слова ищем в оригинале или в транслитерации
    const matchWords = searchTerms.every(
      (term, index) =>
        label.includes(term) ||
        label.includes(searchTermsTranslit[index]) ||
        label.includes(keyboardLayout[index])
    );

    return matchWords;
  };

  const handleKeyPress = (event, index) => {
    if (event.key === "Enter" && event.shiftKey) {
      if (index === rows.length - 1) {
        addRow();

        // Задержка для установки фокуса после рендера новой строки
        setTimeout(() => {
          const lastRowIndex = rows.length;
          const firstCellInput = rowRefs.current[lastRowIndex]?.current;
          if (firstCellInput) {
            firstCellInput.focus();
          }
        }, 0);
      }
    }
  };

  return (
    <Section
      id="products"
      className="products-section"
      $isVisible={isProductsSectionVisible} // Изменено с isVisible на $isVisible
    >
      <h2>Товары</h2>
      <Table widths={[40, 10, 10, 10, 10, 10, 10, 10]}>
        <thead>
          <tr>
            <th>Наименование</th>
            <th>Кол-во</th>
            <th>Ед.изм</th>
            <th>Масса</th>
            <th>Общая масса</th>
            <th>Объем</th>
            <th>Общий объем</th>
            <th>Кнопки</th>
          </tr>
        </thead>
        <tbody id="products-table-body">
          {rows.map((row, index) => (
            <tr key={index}>
              <td
                data-tooltip-id="tooltip"
                data-tooltip-content={getTooltipContent(
                  row.selectedItem ? row.selectedItem.value : null,
                  row.quantity
                )}
              >
                <Select
                  ref={rowRefs.current[index]} // Привязка рефа к текущей строке
                  options={itemOptions}
                  value={row.selectedItem}
                  onChange={(selectedOption) =>
                    handleItemChange(selectedOption, index)
                  }
                  onKeyDown={(event) => handleKeyPress(event, index)}
                  placeholder="Выберите наименование или начните вводить"
                  isClearable={true}
                  isSearchable={true}
                  menuPortalTarget={document.body}
                  styles={customStyles}
                  filterOption={filterOption}
                />
              </td>
              <td>
                <Input
                  type="number"
                  name="quantity[]"
                  value={row.quantity}
                  onChange={(event) => handleQuantityChange(event, index)}
                  onKeyDown={(event) => handleKeyPress(event, index)} // Обработчик для Enter
                  placeholder=""
                />
              </td>
              <td>{row.unit || "шт"}</td>
              <td className="item-mass">
                {parseFloat(row.materialMass).toFixed(2)}
              </td>
              <td className="total-mass">{row.materialSummMass}</td>
              <td className="item-volume">
                {parseFloat(row.materialVolume).toFixed(2)}
              </td>
              <td className="total-volume">{row.materialSummVolume}</td>
              <td>
                <ButtonGroupWrapper>
                  <RedButton onClick={() => deleteRow(index)}>
                    <Icon className="material-icons">delete</Icon>
                  </RedButton>
                  <BlueButton onClick={() => copyRow(index)}>
                    <Icon className="material-icons">content_copy</Icon>
                  </BlueButton>
                </ButtonGroupWrapper>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="4"></td>
            <td id="total-sum-mass"></td>
            <td></td>
            <td id="total-sum-volume"></td>
            <td>
              <PurpleButton
                onClick={addRow}
                title="Добавить новую строку (Shift+Enter на последней строке)"
              >
                Новая строка
              </PurpleButton>
            </td>
          </tr>
        </tfoot>
      </Table>
      {tooltipPortal}
    </Section>
  );
};
export default Products2OS;
